import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  Table,
  Alert,
  CardBody,
  CardHeader,
  Pagination,
} from "reactstrap";

import {
  appointmentEndTimeWithTimeZone,
  appointmentStartTimeWithTimeZone,
} from "../../utilities/TimeZone";
import {
  startAppointment,
  getUserAppointments,
  cancelUserAppointment,
} from "../../../src/redux/store/actions/AppointmentActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { DATE_TIME_FORMAT } from "../../constants/GeneralConstants";
import "../../styles/styles.css";

import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";
import PaginateBar from "../Common/Pagination";
import AppointmentAssignee from "./AppointmentAssignee";
import StorageConstants from "../../constants/StorageConstants";

const sortingDefaultState = {
  asecTime: false,
  descTime: false,
  asecDate: false,
  descDate: false,
  asecDocName: false,
  descDocName: false,
  asecPurpose: false,
  descPurpose: false,
  asecPatientName: false,
  descPatientName: false,
};

class AppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      isLoading: false,
      displayModal: false,
      sortedBy: {
        asecTime: false,
        descTime: false,
        asecDate: false,
        descDate: false,
        asecDocName: false,
        descDocName: false,
        asecPurpose: false,
        descPurpose: false,
        asecPatientName: false,
        descPatientName: false,
      },

      isOpen: false,
      sortingBy: "",
      totalCount: 1,
      currentPage: 1,
      appointments: [],
      selectedAppointment: null,
      isStaffUser: localStorage.getItem(StorageConstants.LOGGED_IN_USER_ROLE),
    };
  }

  componentDidMount() {
    const { currentPage } = this.state;
    this.fetchAppointments(currentPage);
  }

  fetchAppointments = (num, sortBy = "") => {
    this.setState({ isLoading: true });
    this.props
      .getUserAppointments(num, sortBy)
      .then((response) => {
        this.setState({
          isLoading: false,
          appointments: response.list,
          totalCount: response.total_count,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLoading: false });
      });
  };

  sortArrayWithDate = () => {
    let pageNum = 1;
    let criteria = "appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecDate: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayWithDescDate = () => {
    let pageNum = 1;
    let criteria = "-appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descDate: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayWithTime = () => {
    let pageNum = 1;
    let criteria = "appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecTime: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayWithDescTime = () => {
    let pageNum = 1;
    let criteria = "-appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descTime: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayDocName = () => {
    let pageNum = 1;
    let criteria = "doctor_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecDocName: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayDescDocName = () => {
    let pageNum = 1;
    let criteria = "-doctor_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descDocName: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayPatientName = () => {
    let pageNum = 1;
    let criteria = "patient_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecPatientName: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayDescPatientName = () => {
    let pageNum = 1;
    let criteria = "-patient_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descPatientName: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayByPurpose = () => {
    let pageNum = 1;
    let criteria = "reason_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecPurpose: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  sortArrayByDescPurpose = () => {
    let pageNum = 1;
    let criteria = "-reason_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descPurpose: true,
      },
    });
    this.fetchAppointments(pageNum, criteria);
  };

  startAppointment = (appointmentId) => {
    this.setState({
      error: null,
      success: null
    });
    this.props
      .startAppointment(appointmentId)
      .then((response) => {
        if (response.success) {
          this.props.goToAppointmemtRoom(response, appointmentId);
        } else {
          this.setState({
            error: response.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
        });
      });
  };

  cancelAppointment = () => {
    this.setState({ isLoading: true, error: null });
    this.props
      .cancelUserAppointment(this.state.selectedAppointment)
      .then((response) => {
        const { currentPage } = this.state;
        this.fetchAppointments(currentPage);

        if (response.success) {
          this.setState({ success: response.message, displayModal: false });
        } else {
          this.setState({ error: response.message, displayModal: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.setState({
          error: error.message,
          displayModal: false,
        });
      });
  };

  cancelButtonHandler = (appointmentId) => {
    this.setState({ selectedAppointment: appointmentId, success: null, error: null, displayModal: true });

  };

  getPageData = (num) => {
    const { sortingBy } = this.state;
    this.setState({ currentPage: num });
    this.fetchAppointments(num, sortingBy);
  };

  submissionHandler = (msg) => {
    const { currentPage } = this.state;
    this.setState({ success: msg, isOpen: false })
    this.fetchAppointments(currentPage);
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen, error: null });
  };

  render() {
    const {
      error,
      isOpen,
      success,
      sortedBy,
      isLoading,
      totalCount,
      currentPage,
      isStaffUser,
      appointments,
      displayModal,
    } = this.state;
    const {
      asecTime,
      descTime,
      asecDate,
      descDate,
      asecDocName,
      descDocName,
      asecPurpose,
      descPurpose,
      asecPatientName,
      descPatientName,
    } = sortedBy;
    return (
      <Card className="mb-0 border-0 border-radius-0 mb-5">
        <CardHeader className="d-flex align-items-center">
          <i className="fa fa-align-justify"></i>
          <h2>Appointments</h2>
        </CardHeader>
        <CardBody>
          {!!error && <Alert color="danger">{error}</Alert>}
          {!!success && <Alert color="success">{success}</Alert>}
          <Table responsive hover>
            <thead>
              <th scope="col" className="m-0">
                <div className="flex-centered">
                  <span>Client</span>
                  <span className="d-flex flex-column">
                    <i
                      onClick={this.sortArrayPatientName}
                      className={`fa fa-caret-up fa-lg pointer ${
                        asecPatientName && "active-sorting"
                      }`}
                    ></i>
                    <i
                      onClick={this.sortArrayDescPatientName}
                      className={`fa fa-caret-down fa-lg pointer ${
                        descPatientName && "active-sorting"
                      }`}
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" className="m-0">
                <div className="flex-centered m-0">
                  <span>Assigned Staff</span>
                  <span className="d-flex flex-column">
                    <i
                      onClick={this.sortArrayDocName}
                      className={`fa fa-caret-up fa-lg pointer ${
                        asecDocName && "active-sorting"
                      }`}
                    ></i>
                    <i
                      onClick={this.sortArrayDescDocName}
                      className={`fa fa-caret-down fa-lg pointer ${
                        descDocName && "active-sorting"
                      }`}
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" className="m-0">
                <div className="flex-centered m-0">
                  <span>Purpose</span>
                  <span className="d-flex flex-column">
                    <i
                      onClick={this.sortArrayByPurpose}
                      className={`fa fa-caret-up fa-lg pointer ${
                        asecPurpose && "active-sorting"
                      }`}
                    ></i>
                    <i
                      onClick={this.sortArrayByDescPurpose}
                      className={`fa fa-caret-down fa-lg pointer ${
                        descPurpose && "active-sorting"
                      }`}
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" className="m-0">
                <div className="flex-centered m-0">
                  <span>Date</span>
                  <span className="d-flex flex-column">
                    <i
                      onClick={this.sortArrayWithDate}
                      className={`fa fa-caret-up fa-lg pointer ${
                        asecDate && "active-sorting"
                      }`}
                    ></i>
                    <i
                      onClick={this.sortArrayWithDescDate}
                      className={`fa fa-caret-down fa-lg pointer ${
                        descDate && "active-sorting"
                      }`}
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" className="m-0">
                <div className="flex-centered m-0">
                  <span>Time</span>
                  <span className="d-flex flex-column">
                    <i
                      onClick={this.sortArrayWithTime}
                      className={`fa fa-caret-up fa-lg pointer ${
                        asecTime && "active-sorting"
                      }`}
                    ></i>
                    <i
                      onClick={this.sortArrayWithDescTime}
                      className={`fa fa-caret-down fa-lg pointer ${
                        descTime && "active-sorting"
                      }`}
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" className="m-0"></th>
              {isStaffUser !== 3 && <th th scope="col" className="m-0"></th>}
            </thead>
            <tbody>
              {appointments.length > 0 &&
                appointments.map((appointment) => (
                  <tr key={appointment.id.toString()}>
                    <td>{appointment.patient_name}</td>
                    <td>{appointment.doctor_name}</td>
                    <td>{appointment.reason_name}</td>
                    <td>
                      {moment(appointment.appointment_localtime).format(
                        DATE_TIME_FORMAT.DATE
                      )}
                    </td>
                    <td>
                      {appointmentStartTimeWithTimeZone(appointment) +
                        " - " +
                        appointmentEndTimeWithTimeZone(appointment)}
                    </td>
                    <td className="text-nowrap">
                      <button
                        className="btn btn-primary px-3"
                        onClick={() => this.startAppointment(appointment.id)}
                      >
                        Join
                      </button>

                      <button
                        className="btn btn-secondary"
                        onClick={() => this.cancelButtonHandler(appointment.id)}
                      >
                        Cancel
                      </button>
                    </td>
                    {isStaffUser != 3 && (
                      <td>
                        <div className="d-flex">
                          <button
                            className="has-icon"
                            onClick={() => {
                              this.toggle();
                              this.setState({
                                selectedAppointment: appointment,
                              });
                            }}
                          >
                            <i className="ficon-pencil11"></i>
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {appointments.length === 0 && (
                <tr>
                  <th scope="row"></th>
                  <td></td>
                  <td>No Record Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          {totalCount / 15 > 1 && (
            <Pagination className="pagination-wrapper">
              <PaginateBar
                pageNum={currentPage}
                changePageNo={this.getPageData}
                totalPages={Math.ceil(totalCount / 15)}
              />
            </Pagination>
          )}
          {isLoading && <Loader />}
        </CardBody>
        {!!isOpen && (
          <AppointmentAssignee
            open={isOpen}
            appointment={this.state.selectedAppointment}
            toggle={this.toggle}
            submissionHandler={this.submissionHandler}
          />
        )}
        <ModalComponent
          isOpen={displayModal}
          actionHandler={() => {
            this.cancelAppointment();
          }}
          cancelHandler={() => {
            this.setState({ displayModal: false });
          }}
          title="Are you sure you want to cancel this appointment?"
        />
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { startAppointment, getUserAppointments, cancelUserAppointment },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppointmentList));
