import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { createStore, compose, applyMiddleware } from 'redux';

const enhancerList = [];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancer = composeEnhancers(
    applyMiddleware(thunk),
    ...enhancerList
);

const store = createStore(rootReducer, {}, composedEnhancer);

export {
    store
};