import { success } from "./index";
import { USER_INVITATION } from "../../../../src/constants/ActionConstants";
import InvitationService from "../../services/InvitationService";

export function getAllInvites(pageNum, sortBy) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await InvitationService.getAllInvites(pageNum, sortBy);
        dispatch(
          success(USER_INVITATION.USER_INVITATION_SUCCESS, response, dispatch)
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function sendInvite(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await InvitationService.sendInvite(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function cancelInvite(inviteId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await InvitationService.cancelInvite(inviteId);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
