import { success } from "./index";
import { GET_CLINIC } from "../../../../src/constants/ActionConstants";
import ClicnicService from "../../services/ClicnicService";

export function getClinicInfo() {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClicnicService.getClinicInfo();
        dispatch(success(GET_CLINIC.GET_CLINIC_SUCCESS, response, dispatch));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function saveClinicInfo(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClicnicService.saveClinicInfo(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}


export function getClinicSpecialities() {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClicnicService.getClinicSpecialities();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}


export function deleteClinicSpecialities(index) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClicnicService.deleteClinicSpecialities();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function updateClinicSpecialities(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClicnicService.updateClinicSpecialities(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
