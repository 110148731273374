import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./api/GenericResponseHandler";
import { ApiClient } from "./api/ApiClient";
import StorageConstants from "../../constants/StorageConstants";

export function login(model) {
  const url = `${API_CONSTANTS.API_ROUTES.AUTHENTICATE_USER}`;
  return ApiClient(null)
    .post(url, { user: model })
    .then(handleResponse)
    .catch(handleError);
}

export function resetPassword(params) {
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  const url = `${API_CONSTANTS.API_ROUTES.RESET_PASSWORD}`;
  return ApiClient(token)
    .put(url, { user: params })
    .then(handleResponse)
    .catch(handleError);
}

export function sendForgotPassword(params) {
  const url = `${API_CONSTANTS.API_ROUTES.SEND_FORGOT_EMAIL}`;
  return ApiClient(null)
    .post(url, { user: params })
    .then(handleResponse)
    .catch(handleError);
}

export function setNewPassword(params) {
  const url = `${API_CONSTANTS.API_ROUTES.SEND_FORGOT_EMAIL}`;
  return ApiClient(null)
    .put(url, { user: params })
    .then(handleResponse)
    .catch(handleError);
}

export function getUsers(pageNum, sortBy) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_USERS}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":pageNum", pageNum);
  if (sortBy === "name") {
    url = url + "&sort=name";
  }
  if (sortBy === "email") {
    url = url + "&sort=email";
  }
  if (sortBy === "staff_status") {
    url = url + "&sort=staff_status";
  }
  if (sortBy === "speciality_name") {
    url = url + "&sort=speciality_name";
  }
  if (sortBy === "-name") {
    url = url + "&sort=-name";
  }
  if (sortBy === "-email") {
    url = url + "&sort=-email";
  }
  if (sortBy === "-staff_status") {
    url = url + "&sort=-staff_status";
  }
  if (sortBy === "-speciality_name") {
    url = url + "&sort=-speciality_name";
  }
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function setUserAssignee(id) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url =
    `${API_CONSTANTS.API_ROUTES.USERS_ASSIGNEE_1}` +
    id +
    `${API_CONSTANTS.API_ROUTES.USERS_ASSIGNEE_2}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url).then(handleResponse).catch(handleError);
}

export function deleteUser(id) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url =
    `${API_CONSTANTS.API_ROUTES.USERS_DELETE_1}` +
    id +
    `${API_CONSTANTS.API_ROUTES.USERS_DELETE_2}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).delete(url).then(handleResponse).catch(handleError);
}

export function approveUser(ids) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  let url = `${API_CONSTANTS.API_ROUTES.USERS_APPROVE}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url, ids).then(handleResponse).catch(handleError);
}

export function addNewUser(params) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.ADD_NEW_USER}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .post(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function editUser(params, id) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.EDIT_USER}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":userId", id);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .put(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function getSelectedUser(id) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_SELECTED_USER}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":userId", id);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function logoutUser() {
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .delete(API_CONSTANTS.API_ROUTES.USER_LOGOUT)
    .then(handleResponse)
    .catch(handleError);
}

export default {
  login,
  resetPassword,
  sendForgotPassword,
  setNewPassword,
  getUsers,
  setUserAssignee,
  deleteUser,
  approveUser,
  addNewUser,
  getSelectedUser,
  editUser,
  logoutUser,
};
