import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants/GeneralConstants";

export function appointmentStartTimeWithTimeZone(appointment) {
  var startTimeUTC = moment.utc(appointment.appointment_start_time).toDate();
  var startTime = moment(startTimeUTC).local().format("HH:mm");
  return convertTime(startTime);
}

export function appointmentEndTimeWithTimeZone(appointment) {
  var endimeUTC = moment.utc(appointment.appointment_end_time).toDate();
  var endTime = moment(endimeUTC).local().format("HH:mm");
  return convertTime(endTime);
}

export function convertLocalTimeToDate(time) {
  return moment(time).local().format(DATE_TIME_FORMAT.DATE);
}

const convertTime = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};


export function timeConversion(time) {
  var startTimeUTC = moment.utc(time).toDate();
  var startTime = moment(startTimeUTC).local().format("HH:mm");
  return convertTime(startTime);
}
