import React, { Component } from "react";
import moment from "moment";
import {
  Modal,
  Table,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { timeConversion } from "../../../utilities/TimeZone";
import { DATE_TIME_FORMAT } from "../../../constants/GeneralConstants";

import Loader from "../../Common/Loader";

class AppointmentLogs extends Component {
  render() {
    const {
      cancelLogs,
      isModalOpen,
      isLogsLoading,
      appointmentLogs,
    } = this.props;
    return (
      <Modal isOpen={isModalOpen} className="modal-lg">
        <ModalHeader>Appointment Logs</ModalHeader>
        <ModalBody
          style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}
        >
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Event</th>
                <th scope="col">At</th>
              </tr>
            </thead>
            <tbody>
              {appointmentLogs.length > 0 &&
                appointmentLogs.map(
                  ({ event_type, user_name, event_time }, index) => (
                    <tr key={index}>
                      <td>{user_name}</td>
                      <td>{event_type}</td>
                      <td>
                        {moment(event_time).format(DATE_TIME_FORMAT.DATE)}
                        &nbsp; - &nbsp;{timeConversion(event_time)}
                      </td>
                    </tr>
                  )
                )}
              {appointmentLogs.length === 0 && (
                <tr>
                  <th scope="row"></th>
                  <td>No Record Found</td>
                  <td></td>
                </tr>
              )}
              {!!isLogsLoading && <Loader />}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelLogs}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AppointmentLogs;
