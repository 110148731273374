export const ROUTE_CONSTANTS = {
    LANDING_SCREEN: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    APPOINTMENTS: '/appointments',
    APPOINTMENTS_HISTORY: '/appointments-history',
    CLIENTS: '/clients',
    USERS: '/users',
    INVITATION: '/invitation',
    ADD_USERS: '/users/add',
    EDIT_USERS: '/users/edit',
    APPOINTMENT_START: '/appointment/start',
    SETTINGS: '/settings',
    RESET_PASSWORD: '/reset-password',
    FORGOT_PASSWORD_SEND_EMAIL: '/send-email',
    SET_NEW_PASSWORD: '/set-password',

}

