import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";

import {
  editUser,
  getSelectedUser,
} from "../../redux/store/actions/UserActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { TEAM_ROLE } from "../../constants/GeneralConstants";
import StorageConstants from "../../constants/StorageConstants";
import { getClinicSpecialities } from "../../redux/store/actions/ClinicActions";
import Loader from "../Common/Loader";

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.selectedUser.id,
      email: "",
      lastName: "",
      firstName: "",
      profession: "",
      isApproved: "",
      practiceManager: "",
      specialtyList: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
    this.props
      .getSelectedUser(this.props.selectedUser.id)
      .then((response) => {
        this.setState({
          firstName: response.firstname,
          lastName: response.lastname,
          email: response.email,
          profession: response.speciality ? response.speciality.name : "",
          practiceManager:
            response.single_role === TEAM_ROLE.PRACTICE_MANAGER ? true : false,
          isApproved: response.is_approved,
        });
      })
      .catch(() => {});
    this.getClinicSpecialities();
  }

  getClinicSpecialities() {
    this.props
      .getClinicSpecialities()
      .then((response) => {
        console.log("Response ", response);
        this.setState({ specialtyList: response });
      })
      .catch((error) => {});
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePracticeManagerChange = (event) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        practiceManager: !prevState.practiceManager,
      };
    });
  };

  handleIsApprovedChange = (event) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isApproved: !prevState.isApproved,
      };
    });
  };

  getSpeciality() {
    var speciality = null;

    if (this.state.specialtyList.length == 0) {
      //incase there is no speciality
      return speciality;
    } else {
      let item = this.state.specialtyList.filter(
        (p) => p.name === this.state.profession
      );
      if (item.length > 0) {
        speciality = item[0];
      } else {
        speciality = this.state.specialtyList[0];
      }
      return speciality;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let id = this.state.userId;
    let specialityId = this.getSpeciality();

    let options = {
      user: {
        id: id,
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        practice_manager: this.state.practiceManager,
        is_approved: this.state.isApproved,
      },
    };
    if (specialityId) {
      options.user.speciality_id = specialityId.id;
    }

    this.setState({ isLoading: true });
    this.props
      .editUser(options, this.state.id)
      .then(() => {
        this.props.editUserCompleted();
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  toggle = (i) => {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      dropdownOpen: newArray,
    });
  };

  didSelectSpecialty = () => {
    var event = document.getElementById("profession");
    let item = this.state.specialtyList.filter((p) => p.name === event.value);
    this.setState({
      profession: item[0].name,
    });
  };

  render() {
    return (
      <div className="animated fadeIn">
        {this.state.isLoading && <Loader />}
        <Card className="border-0 border-radius-0">
          <CardHeader>
            <h2>Edit Team Member</h2>
          </CardHeader>
          <CardBody>
            <Form className="max-width-500" onSubmit={this.handleSubmit}>
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-firstName">
                    First Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-firstName"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    placeholder="First Name"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-lastName">
                    Last Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-lastName"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-email">
                    Email
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-profession">
                    Specialty
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <select
                    className="custom-select form-control"
                    id="profession"
                    onChange={this.didSelectSpecialty}
                  >
                    {this.state.specialtyList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((list) => (
                        <option
                          value={list.name}
                          selected={this.state.profession === list.name ?? true}
                        >
                          {list.name}
                        </option>
                      ))}
                  </select>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="9">
                  <FormGroup check className="pl-0">
                    <Input
                      id="practiceManager"
                      className="form-check-input"
                      type="checkbox"
                      name="practiceManager"
                      value={this.state.practiceManager}
                      checked={this.state.practiceManager}
                      onChange={this.handlePracticeManagerChange}
                    />
                    <Label
                      check
                      for="practiceManager"
                      className="form-check-label"
                    >
                      {" "}
                      &nbsp;Practice Manager?
                    </Label>
                  </FormGroup>

                  <FormGroup check className="pl-0">
                    <Input
                      id="isApproved"
                      className="form-check-input"
                      type="checkbox"
                      name="isApproved"
                      value={this.state.isApproved}
                      checked={this.state.isApproved}
                      onChange={this.handleIsApprovedChange}
                    />
                    <Label check for="isApproved" className="form-check-label">
                      {" "}
                      &nbsp;Active?
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button
                  className="btn btn-outline-dark mr-2"
                  color="secondary"
                  size="sm"
                  onClick={() => this.props.editUserCompleted()}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  size="sm"
                  color="success"
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        {/* </Col> */}
        {this.state.redirectToDashboard ? (
          <Redirect to={{ pathname: "/dashboard" }} />
        ) : (
          ""
        )}
        {/* </Row> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { editUser, getSelectedUser, getClinicSpecialities },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
