export const API_CONSTANTS = {
    HEADER_KEYS: {
        AUTHORIZATION: 'Authorization',
        CONTENT_TYPE: "Content-Type",
    },
    CONTENT_TYPES: {
        APPLICATION_JSON: "application/json",
        IMAGE: "image/*"

    },


    API_ROUTES: {
        AUTHENTICATE_USER: '/users/sign_in.json',
        RESET_PASSWORD: '/users.json',
        USER_LOGOUT: '/users/sign_out.json',
        SEND_FORGOT_EMAIL: '/api/v1/users/password.json',
        GET_USER_APPOINTMENT: '/api/clinics/:id/appointments/all_upcoming.json?page=:pageNum&size=15',
        CANCEL_APPOINTMENT: '/api/clinics/:id/appointments/:appId/cancel.json',
        GET_APPOINTMENT_NOTES: '/api/clinics/:id/appointments/:appId/appointment_notes.json',
        ADD_APPOINTMENT_NOTES: '/api/clinics/:id/appointments/:appId/appointment_notes/:noteId.json',
        ADD_PUBLIC_APPOINTMENT_NOTES: '/api/clinics/:id/appointments/:appId/appointment_notes/:noteId/publish.json',
        CONFIGURE_APPOINTMENT_NOTES: '/api/clinics/:id/appointments/:appId/appointment_notes.json',
        GET_USER_APPOINTMENT_HISTORY: '/api/clinics/:id/appointments/history.json?page=:pageNum&size=15',
        GET_USER_APPOINTMENT_LOGS: '/api/clinics/:id/appointments/:appId/events.json',
        GET_CLIENTS: '/api/clinics/:id/users/clients.json?page=:pageNum&size=15',
        GET_USERS: '/api/clinics/:id/users.json?page=:pageNum&size=15',
        GET_SELECTED_USER: '/api/clinics/:id/users/:userId.json',
        USERS_ASSIGNEE_1: '/api/clinics/:id/users/',
        USERS_ASSIGNEE_2: '/default_assignee.json',
        USERS_DELETE_1: '/api/clinics/:id/users/',
        USERS_DELETE_2: '.json',
        USERS_APPROVE: '/api/clinics/:id/users/approve.json',
        ADD_NEW_USER: '/api/clinics/:id/users.json',
        ADD_NEW_CLIENT: '/api/clinics/:id/clients.json',
        EDIT_USER: '/api/clinics/:id/users/:userId.json',
        START_APPOINTMENT_1: '/api/clinics/:id/appointments/',
        START_APPOINTMENT_2: '/start.json',
        GET_INVITATION: '/api/clinics/:id/invitations.json?page=:pageNum&size=15',
        SEND_INVITATION: '/api/clinics/:id/invitations.json',
        CANCEL_INVITATION: '/api/clinics/:id/invitations/:inviteId.json',
        GET_CLINIC: '/api/clinics/:id.json',
        SAVE_CLINIC: '/api/clinics/:id.json',
        GET_CLINIC_SPECIALITIES: '/api/clinics/:id/specialities.json',
        DELETE_CLINIC_SPECIALITIES: '/api/clinics/:id/specialities.json',
        ADD_CLINIC_SPECIALITIES: '/api/clinics/:id/specialities.json',
        GET_AVAILABLE_DATES: '/api/clinics/:id/appointments/available_dates.json',
        GET_AVAILABLE_APPOINTMENTS: '/api/clinics/:id/appointments/available?start_date=:startDate&end_date=:endDate',
        GET_APPOINTMENT_REASONS: '/api/clinics/:id/appointments/reasons.json',
        SCHEDULE_APPOINTMENT: '/api/clinics/:id/appointments/:appointment_id/schedule.json',
        END_APPOINTMENT_CALL: '/api/clinics/:id/appointments/:appointment_id/complete.json',
        ASSIGN_APPOINTMENT: '/api/clinics/:id/appointments/:appointment_id/assign_user.json'


    }
};