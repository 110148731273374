import { API_CONSTANTS } from '../../constants/ApiConstants';
import { handleResponse, handleError } from './api/GenericResponseHandler';
import { ApiClient } from './api/ApiClient';
import StorageConstants from '../../constants/StorageConstants';


export function getClinicInfo() {
    var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
    var url = `${API_CONSTANTS.API_ROUTES.GET_CLINIC}`;
    url = url.replace(':id', clinicId);
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN)
    return ApiClient(token).get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function saveClinicInfo(params) {
    var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
    var url = `${API_CONSTANTS.API_ROUTES.SAVE_CLINIC}`;
    url = url.replace(':id', clinicId);
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN)
    return ApiClient(token).put(url, params)
        .then(handleResponse)
        .catch(handleError);
}


export function getClinicSpecialities() {
    var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
    var url = `${API_CONSTANTS.API_ROUTES.GET_CLINIC_SPECIALITIES}`;
    url = url.replace(':id', clinicId);
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN)
    return ApiClient(token).get(url)
        .then(handleResponse)
        .catch(handleError);
}


export function deleteClinicSpecialities(index) {

    var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
    var url = `${API_CONSTANTS.API_ROUTES.DELETE_CLINIC_SPECIALITIES}`;
    url = url.replace(':id', clinicId);
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN)
    return ApiClient(token).get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function updateClinicSpecialities(params) {

    var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
    var url = `${API_CONSTANTS.API_ROUTES.ADD_CLINIC_SPECIALITIES}`;
    url = url.replace(':id', clinicId);
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN)
    return ApiClient(token).put(url, params)
        .then(handleResponse)
        .catch(handleError);
}

export default {
    getClinicInfo,
    saveClinicInfo,
    getClinicSpecialities,
    deleteClinicSpecialities,
    updateClinicSpecialities

}