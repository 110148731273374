import {
  STATUS_CODES,
  HTTP_STATUS_CODES,
  STATUS_CODES_TYPES,
} from "../../../constants/HttpStatusCodes";
import StorageConstants from "../../../../src/constants/StorageConstants";

export async function handleResponse(response) {
  if (HTTP_STATUS_CODES[response.status].type === STATUS_CODES_TYPES.SUCCESS) {
    const accessToken = response.headers["authorization"];
    accessToken &&
      localStorage.setItem(StorageConstants.AUTH_TOKEN, accessToken);
    return response.data;
  } else {
    handleError(response);
  }
}

export async function handleError(error) {
  console.log({ error });
  let errorMessage = {
    title: "",
    message: "",
    code: 500,
    errors: [],
  };

  console.log("RESPONSE ERROR IN API CALL : \n", error.response);

  if (!error.response) {
    errorMessage.title = "Error";
    errorMessage.message = error.response.data.message
      ? error.message
      : "Network error";
  } else if (
    error.response.status === STATUS_CODES.FORBIDDEN ||
    error.response.status === STATUS_CODES.UNAUTHORIZED
  ) {
    localStorage.removeItem(StorageConstants.CLINIC_ID);
    localStorage.removeItem(StorageConstants.AUTH_TOKEN);
    localStorage.removeItem(StorageConstants.LOGGED_IN_USER);
    localStorage.removeItem(StorageConstants.LOGGED_IN_USER_ROLE);
    errorMessage.code = error.response.status;
    errorMessage.message = error.response.data.error;
  }

  else {
    errorMessage.code = error.response.data.responseCode;
    errorMessage.title = STATUS_CODES_TYPES.ERROR;
    if (error.response.data.error) {
      errorMessage.message = error.response.data.error;
    } else {
      errorMessage.message = error.response.data.message;
    }

  }

  throw errorMessage;
}
