import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Alert,
  Table,
  CardBody,
  Pagination,
  CardHeader,
} from "reactstrap";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import {
  getUsers,
  deleteUser,
  setUserAssignee,
} from "../../redux/store/actions/UserActions";

import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";
import PaginateBar from "../Common/Pagination";

const sortingDefaultState = {
  asecName: false,
  descName: false,
  asecEmail: false,
  descEmail: false,
  asecActive: false,
  descActive: false,
  asecSpecialty: false,
  descSpecialty: false,
};

class UserListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      totalCount: 1,
      currentPage: 1,

      error: null,
      success: null,
      isLoading: false,
      sortedBy: {
        asecName: false,
        descName: false,
        asecEmail: false,
        descEmail: false,
        asecActive: false,
        descActive: false,
        asecSpecialty: false,
        descSpecialty: false,
      },

      sortingBy: "",
      checkboxes: [],
      configOption: {},
      selectedUsers: [],
      displayModal: null,
      checkAllBox: false,
      selectedUser: null,
    };
  }

  initializeCheckboxes = () => {
    const { content } = this.state.data;
    const { checkboxes } = this.state;
    if (this.isDataProvided() && content.length !== checkboxes.length) {
      this.setState({ checkboxes: new Array(content.length).fill(false) });
    }
  };

  componentDidMount() {
    const { currentPage } = this.state;
    this.fetchUsers(currentPage);
    this.initializeCheckboxes();
  }

  fetchUsers = (num, sortBy = "") => {
    this.setState({ isLoading: true });
    this.props
      .getUsers(num, sortBy)
      .then((response) => {
        const data = {
          content: response.list.map((item) => {
            item.checked = false;
            return item;
          }),
        };
        this.setState({
          data: data,
          isLoading: false,
          totalCount: response.total_count,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLoading: false });
      });
  };

  getPageData = (num) => {
    const { sortingBy } = this.state;
    this.setState({ currentPage: num });
    this.fetchUsers(num, sortingBy);
  };

  isDataProvided = () => {
    const { data } = this.state;
    return data && data.content && data.content.length > 0;
  };

  toggleAllSelection = () => {
    const { checkAllBox } = this.state;
    const data = {
      content: this.state.data.content.map((item) => {
        item.checked = !checkAllBox;
        return item;
      }),
    };
    this.setState({ data: data, checkAllBox: !checkAllBox });
  };

  toggleSelection = (itemIndex) => {
    const data = {
      content: this.state.data.content.map((item, index) => {
        if (index === itemIndex) {
          item.checked = !item.checked;
        }
        return item;
      }),
    };
    this.setState({ data: data, checkAllBox: false });
  };

  goToNewUser = () => {
    this.props.goToNewUser();
  };

  goToEditUser = (user) => {
    this.props.goToEditUser(user);
  };

  deleteUser = () => {
    this.setState({ isLoading: true });
    this.props
      .deleteUser(this.state.selectedUser)
      .then(() => {
        const { currentPage } = this.state;
        this.fetchUsers(currentPage > 1 ? currentPage - 1 : 1);
        this.setState({
          success: true,
        });
        this.setState({ isLoading: false });
        this.setState({ displayModal: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        this.setState({ displayModal: false });
        this.setState({
          error: true,
        });
      });
  };

  assignClinic = (userId) => {
    this.props
      .setUserAssignee(userId)
      .then(() => {
        const { currentPage } = this.state;
        this.fetchUsers(currentPage);
      })
      .catch(() => {});
  };

  checkRole(role) {
    var updateRole = "Admin";
    if (role === "practice_manager") {
      updateRole = "Practice Manager";
    } else if (role === "staff") {
      updateRole = "Staff";
    }
    return updateRole;
  }

  checkStatus(status) {
    var updatedStatus = "No";
    if (status === true) {
      updatedStatus = "Yes";
    }
    return updatedStatus;
  }

  sortArrayByName = () => {
    let pageNum = 1;
    let criteria = "name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecName: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayBySpecialty = () => {
    let pageNum = 1;
    let criteria = "speciality_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecSpecialty: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByDescSpecialty = () => {
    let pageNum = 1;
    let criteria = "-speciality_name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descSpecialty: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByDescName = () => {
    let pageNum = 1;
    let criteria = "-name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descName: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByEmail = () => {
    let pageNum = 1;
    let criteria = "email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecEmail: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByDescEmail = () => {
    let pageNum = 1;
    let criteria = "-email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descEmail: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByActive = () => {
    let pageNum = 1;
    let criteria = "staff_status";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecActive: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  sortArrayByDescActive = () => {
    let pageNum = 1;
    let criteria = "-staff_status";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descActive: true,
      },
    });
    this.fetchUsers(pageNum, criteria);
  };

  deleteActionHandler = (userId) => {
    this.setState({ selectedUser: userId });
    this.setState({ displayModal: true });
  };

  render() {
    const {
      data,
      error,
      success,
      sortedBy,
      isLoading,
      totalCount,
      currentPage,
      displayModal,
    } = this.state;
    const {
      asecName,
      descName,
      asecEmail,
      descEmail,
      asecActive,
      descActive,
      asecSpecialty,
      descSpecialty,
    } = sortedBy;
    return (
      <div className="animated fadeIn">
        {!!isLoading && <Loader />}
        <Card className="mb-0 border-0 border-radius-0 mb-5">
          <CardHeader>
            <Row>
              <Col sm={7} className="d-flex align-items-center">
                <i className="fa fa-align-justify"></i>
                <h2>My Team</h2>
              </Col>
              <Col sm={5} className="mt-2 mt-sm-0">
                <button
                  className="btn btn-outline-dark float-right"
                  onClick={this.goToNewUser}
                >
                  Add Team Member
                </button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {!!error && (
              <Alert color="danger">Couldn't delete the account.</Alert>
            )}
            {!!success && (
              <Alert color="success">
                Team member has been removed successfully.
              </Alert>
            )}
            {!!this.props.success && (
              <Alert color="success">
                Team member has been added successfully.
              </Alert>
            )}
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Name</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByName}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecName && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescName}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descName && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Email</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByEmail}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecEmail && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescEmail}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descEmail && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col">Role</th>
                  <th scope="col">
                    <div className="flex-centered">
                      <span> Specialty </span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayBySpecialty}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecSpecialty && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescSpecialty}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descSpecialty && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Active</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByActive}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecActive && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescActive}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descActive && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col">&nbsp;</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {this.isDataProvided() &&
                  data.content.map((user, rowIndex) => (
                    <tr key={user.id.toString()}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{this.checkRole(user.single_role)}</td>
                      <td>{user.speciality ? user.speciality.name : "N/A"}</td>
                      <td>{this.checkStatus(user.is_approved)}</td>
                      <td>
                        <div className="d-flex">
                          <button
                            className="has-icon"
                            onClick={() => this.goToEditUser(user)}
                          >
                            <i className="ficon-pencil11"></i>
                          </button>
                          {/* {
                            <Link
                              className="has-icon"
                              onClick={() => {
                                this.deleteActionHandler(user.id);
                              }}
                            >
                              <i className="ficon-trash-alt11"></i>
                            </Link>
                          } */}
                        </div>
                      </td>
                      <td>
                        <span>
                          {!user.default_assignee && (
                            <button
                              className="btn btn-secondary"
                              onClick={() => this.assignClinic(user.id)}
                            >
                              Set Assignee
                            </button>
                          )}
                          <em className="small-label">
                            {user.default_assignee && "Default Assignee"}
                          </em>
                        </span>
                      </td>
                    </tr>
                  ))}
                {data.content && data.content.length === 0 && (
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Record Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {totalCount / 15 > 1 && (
              <Pagination className="pagination-wrapper">
                <PaginateBar
                  pageNum={currentPage}
                  changePageNo={this.getPageData}
                  totalPages={Math.ceil(totalCount / 15)}
                />
              </Pagination>
            )}
          </CardBody>
          <ModalComponent
            isOpen={displayModal}
            actionHandler={() => {
              this.deleteUser();
            }}
            cancelHandler={() => {
              this.setState({ displayModal: false });
            }}
            title="Are you sure you want to delete this user?"
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getUsers, setUserAssignee, deleteUser },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserListing));
