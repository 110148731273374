import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./api/GenericResponseHandler";
import { ApiClient } from "./api/ApiClient";
import StorageConstants from "../../constants/StorageConstants";

export function getClients(pageNum, sortBy) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_CLIENTS}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":pageNum", pageNum);
  if (sortBy === "name") {
    url = url + "&sort=name";
  }
  if (sortBy === "email") {
    url = url + "&sort=email";
  }
  if (sortBy === "-name") {
    url = url + "&sort=-name";
  }
  if (sortBy === "-email") {
    url = url + "&sort=-email";
  }
  if (sortBy === "state") {
    url = url + "&sort=state";
  }
  if (sortBy === "-state") {
    url = url + "&sort=-state";
  }
  if (sortBy === "-city") {
    url = url + "&sort=-city";
  }
  if (sortBy === "city") {
    url = url + "&sort=city";
  }
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function addNewClient(params) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.ADD_NEW_CLIENT}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .post(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export default {
  addNewClient,
  getClients,
};
