import React, { Component } from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { validate } from "validate.js";
import { bindActionCreators } from "redux";
import {
  Col,
  Row,
  Form,
  Card,
  Input,
  Alert,
  Button,
  CardBody,
  CardGroup,
  Container,
  InputGroup,
} from "reactstrap";

import LoginLogo from "../../src/assets/img/brand/logo.svg";
import {
  loginUser,
  logoutUser,
} from "../../src/redux/store/actions/UserActions";

import { removeEmptyKeys } from "../utils/utils";
import { ROUTE_CONSTANTS } from "../constants/Routes";

import Loader from "./Common/Loader";

class Login extends Component {
  validationConfig = {
    email: {
      presence: true,
      email: true,
    },
    password: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      isLoading: false,

    };
  }

  componentDidMount() {
    this.props.logoutUser();
  }

  forgotPassword = () => {
    this.props.history.push(ROUTE_CONSTANTS.FORGOT_PASSWORD_SEND_EMAIL);
  };

  login = () => {
    let result = validate(removeEmptyKeys(this.state), this.validationConfig);
    if (!result) {
      this.setState({
        isLoading: true,
        error: null,
      });
      this.props
        .loginUser(this.state)
        .then(() => {
          this.setState({ isLoading: false });
          this.props.history.push(ROUTE_CONSTANTS.APPOINTMENTS);
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            error: err.message,
          });
        });
    } else {
      this.setState({ error: result });
    }
  };

  render() {
    const { user } = this.props;
    const { email, password } = this.state;
    const error = get(user, ["loggedin", "loginAction", "message"]);
    const isLoggingIn = get(user, ["loggedin", "isLoggingIn"]);

    return (
      <div className="app flex-row align-items-center app-login">
        {this.state.isLoading && <Loader />}
        <Container>
          <Row className="justify-content-center">
            <Col md={9} lg={7} xl={6}>
              <CardGroup>
                <Card className="p-4 login-card">
                  <CardBody>
                    <Form error={!!error} disabled={isLoggingIn}>
                      {/* <img src="assets/img/brand/logo.svg" /> */}
                      <img alt="Clinicall logo" src={LoginLogo} />
                      {this.state.error ? (
                        typeof this.state.error === "object" ? null : (
                          <Alert color="danger">{this.state.error}</Alert>
                        )
                      ) : null}
                      <h4>Login</h4>

                      <p className="text-muted">Sign In to your account</p>
                      {error && (
                        <Alert color="danger">
                          <h4 className="alert-heading"> Login Failed</h4>
                          <hr />
                          <p className="mb-0">{error}</p>
                        </Alert>
                      )}
                      <InputGroup className="mb-3">
                        <div className="d-flex flex-column width-100">
                          <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            required={true}
                            onChange={(e) => {
                              this.setState({
                                email: e.target.value,
                                error: null,
                              });
                            }}
                          />
                          {isEmpty(email) && !!this.state.error && (
                            <p className="error-message pt-1">
                              Please enter an email address
                            </p>
                          )}
                        </div>
                      </InputGroup>

                      <InputGroup className="mb-4">
                        <div className="d-flex flex-column width-100">
                          <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            value={password}
                            required={true}
                            onChange={(e) => {
                              this.setState({
                                password: e.target.value,
                                error: null,
                              });
                            }}
                          />
                          {isEmpty(password) && !!this.state.error && (
                            <p className="error-message pt-1">
                              Please enter a password
                            </p>
                          )}
                        </div>
                      </InputGroup>

                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            className="px-4"
                            disabled={get(user, ["loggedin", "isLoggingIn"])}
                            onClick={this.login}
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button
                            color="link"
                            className="px-0"
                            onClick={this.forgotPassword}
                          >
                            Forgot password?
                          </Button>
                        </Col>
                        <Col xs={12} className="pt-3 text-right">
                          <a
                            className="privacypolicy-button"
                            href="https://digitalhealth.folio3.com/solutions/cliniclive/terms-and-conditions/"
                            // eslint-disable-next-line
                            target="_blank"
                          >
                            Terms & Conditions
                          </a>
                          <span> and </span>
                          <a
                            className="privacypolicy-button"
                            href="https://digitalhealth.folio3.com/solutions/cliniclive/privacy-policy/"
                            // eslint-disable-next-line
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser, logoutUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
