import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  Col,
  Form,
  Card,
  Alert,
  Input,
  Label,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import StorageConstants from "../../constants/StorageConstants";

import { validateEmail } from "../../utilities/Validator";
import { addNewClient } from "../../redux/store/actions/ClientsAction";

import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";

class ClientsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      lastName: "",
      firstName: "",
      emailError: "",
      phoneError: "",
      lastNameError: "",
      firstNameError: "",
      displayModal: false,

      error: "",
      success: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
  }

  handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if (key === "phone") {
      this.setState({ [key]: value.replace(/[^0-9 | +]/g, "") });
    }
    if (key === "email") {
      this.setState({ [key]: value });
    } else {
      this.setState({ [key]: value.replace(/[^\w\s]|[0-9]/g, "") });
    }
  };

  validateFields() {
    var isValid = true;

    if (this.state.phone.length < 10) {
      this.setState({
        phoneError: "Phone number must be greater than 10 digits.",
      });
      isValid = false;
    }

    if (this.state.phone.length > 15) {
      this.setState({
        phoneError: "Phone number must be less than 15 digits.",
      });
      isValid = false;
    }

    if (this.state.firstName === "") {
      this.setState({ firstNameError: "Please provide first name." });
      isValid = false;
    }

    if (this.state.lastName === "") {
      this.setState({ lastNameError: "Please provide last name." });
      isValid = false;
    }

    if (this.state.email === "") {
      this.setState({ emailError: "Please provide email." });
      isValid = false;
    }

    if (this.state.phone === "") {
      this.setState({ phoneError: "Please provide phone number." });
      isValid = false;
    }

    let response = validateEmail(this.state.email);
    if (response !== "") {
      this.setState({ emailError: response });
      isValid = false;
    }

    return isValid;
  }

  resetError() {
    this.setState({
      error: "",
      success: "",
      emailError: "",
      phoneError: "",
      lastNameError: "",
      firstNameError: "",
    });
  }
  handleSubmit = (event) => {
    this.resetError();
    event.preventDefault();
    if (this.validateFields()) {
      this.setState({ error: null });
      let params = {
        client: {
          email: this.state.email,
          phone: this.state.phone,
          lastname: this.state.lastName,
          firstname: this.state.firstName,
        },
      };
      this.setState({ isLoading: true });

      this.props
        .addNewClient(params)
        .then((response) => {
          if (response) {
            this.setState({
              phone: "",
              email: "",
              lastName: "",
              firstName: "",
              isLoading: false,
              success: response.message,
            });
            this.props.toggleRendering();
            this.props.alertHandler();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: error.message,
          });
        });
    }
  };

  showModalonCancel = () => {
    if (
      this.state.firstName !== "" ||
      this.state.lastName !== "" ||
      this.state.email !== "" ||
      this.state.phone !== ""
    ) {
      this.setState({ displayModal: true });
    } else {
      this.props.toggleRendering();
    }
  };

  render() {
    const {
      email,
      phone,
      lastName,
      firstName,
      emailError,
      phoneError,
      displayModal,
      lastNameError,
      firstNameError,
      redirectToDashboard,

      error,
      success,
      isLoading,
    } = this.state;

    return (
      <div className="animated fadeIn">
        {!!isLoading && <Loader />}
        {!!error && <Alert color="danger">{error}</Alert>}
        {!!success && <Alert color="success">{success}</Alert>}
        <Card className="border-0 border-radius-0">
          <CardHeader>
            <h2>New Client</h2>
          </CardHeader>
          <CardBody>
            <Form className="max-width-500" onSubmit={this.handleSubmit}>
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="pt-sm-2 mb-1" htmlFor="hf-firstName">
                    First Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-firstName"
                    name="firstName"
                    value={firstName}
                    onChange={this.handleChange}
                    placeholder="First Name"
                    maxLength={50}
                  />
                  {firstNameError && (
                    <p className="error-message">{firstNameError}</p>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="pt-sm-2 mb-1" htmlFor="hf-lastName">
                    Last Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-lastName"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    maxLength={50}
                  />
                  {lastNameError && (
                    <p className="error-message">{lastNameError}</p>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="pt-sm-2 mb-1" htmlFor="hf-email">
                    Email
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="Email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder="Email"
                    maxLength={50}
                  />
                  {emailError && <p className="error-message">{emailError}</p>}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="pt-sm-2 mb-1" htmlFor="hf-lastName">
                    Phone
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={phone}
                    maxLength={15}
                    id="phone"
                    name="phone"
                    limitMaxLength={true}
                    onChange={(value) => {
                      this.setState({ phone: value });
                    }}
                  />
                  {phoneError && <p className="error-message">{phoneError}</p>}
                </Col>
              </FormGroup>

              <FormGroup className="form-actions mt-5">
                <Button
                  size="sm"
                  color="secondary"
                  className="btn btn-outline-dark mr-2"
                  onClick={this.showModalonCancel}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  color="success"
                  className="btn btn-primary"
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
            <ModalComponent
              isOpen={displayModal}
              actionHandler={() => this.props.toggleRendering()}
              cancelHandler={() => {
                this.setState({ displayModal: false });
              }}
              title="Are you sure you want to cancel without saving?"
            />
          </CardBody>
        </Card>

        {redirectToDashboard && <Redirect to={{ pathname: "/dashboard" }} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // users: state.user.allUsers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addNewClient }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsNew);
