import { CLIENTS_ACTIONS } from '../../../constants/ActionConstants';

const initialState = {
    clients: []
};

const clients = (state = initialState, action) => {

    switch (action.type) {

        case CLIENTS_ACTIONS.CLIENTS_SUCCESS:
            return {
                ...state, clients: action.payload
            };

        default:
            return state;
    }
};

export default clients;