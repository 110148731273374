import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Col,
  Form,
  Card,
  Alert,
  Input,
  Label,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import StorageConstants from "../../constants/StorageConstants";

import { validatePassword } from "../../utilities/Validator";

import { addNewUser } from "../../redux/store/actions/UserActions";
import { getClinicSpecialities } from "../../redux/store/actions/ClinicActions";

import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";

class UserNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      lastName: "",
      password: "",
      firstName: "",
      confirmPassword: "",
      practiceManager: false,
      profession: "Neurologist",
      dropdownOpen: new Array(2).fill(false),
      specialtyList: [],
      error: null,
      isLoading: false,
      displayModal: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
    this.getClinicSpecialities();
  }

  getClinicSpecialities() {
    this.props
      .getClinicSpecialities()
      .then((response) => {
        console.log("Response ", response);
        this.setState({ specialtyList: response })
      })
      .catch((error) => {

      });
  }
  componentDidUpdate() { }

  handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if (key === "firstName" || key === "lastName") {
      this.setState({ [key]: value.replace(/[^\w\s]|[0-9]/g, "") });
    } else {
      this.setState({ [key]: value });
    }
  };

  handleCheckBoxChange = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        practiceManager: !prevState.practiceManager,
      };
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: null });
    let specialityId = this.getSpeciality();


    let params = {
      user: {
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
        practice_manager: this.state.practiceManager,
      },
    };

    if (specialityId) {
      params.user.speciality_id = specialityId.id
    }

    this.validateFields(params);
  };
  j
  toggle = (i) => {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      dropdownOpen: newArray,
    });
  };

  getSpeciality() {

    var speciality = null;

    if (this.state.specialtyList.length == 0) { //incase there is no speciality
      return speciality
    } else {
      let item = this.state.specialtyList.filter((p) => p.name === this.state.profession);
      if (item.length > 0) {
        speciality = item[0]
      } else {
        speciality = this.state.specialtyList[0]
      }
      return speciality
    }

  }

  didSelectSpecialty = () => {

    var event = document.getElementById("profession");
    let item = this.state.specialtyList.filter((p) => p.name === event.value);
    this.setState({
      profession: item[0].name,
    });
  };

  validateFields = (params) => {
    var isValidated = this.validatePasswordLocally();
    if (isValidated) {
      this.setState({ isLoading: true, error: null });
      this.props
        .addNewUser(params)
        .then((response) => {
          if (!response.success) {
            this.setState({ isLoading: false, error: response.message });
          } else {
            this.props.alertHandler();
            this.props.addUserCompleted();
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  validatePasswordLocally() {
    let isValidated = true;
    if (this.state.password === "" || this.state.confirmPassword === "") {
      this.setState({ error: "Please fill all the fields." });
      isValidated = false;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ error: "Passwords do not match." });
      isValidated = false;
    } else {
      let response = validatePassword(this.state.password);
      if (response !== "") {
        this.setState({ error: response });
        isValidated = false;
      }
    }

    return isValidated;
  }

  render() {
    const { error, displayModal, isLoading, redirectToDashboard } = this.state;
    return (
      <div className="animated fadeIn">
        {isLoading && <Loader />}
        <Card className="border-0 border-radius-0">
          <CardHeader>
            <h2>New Team Member</h2>
          </CardHeader>
          <CardBody>
            <Form className="max-width-500" onSubmit={this.handleSubmit}>
              {error && <Alert color="danger">{error}</Alert>}
              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-firstName">
                    First Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-firstName"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    placeholder="First Name"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-lastName">
                    Last Name
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="hf-lastName"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-email">
                    Email
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-lastName">
                    Password
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    maxLength={15}
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="hf-lastName">
                    Confirm Password
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    maxLength={15}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    placeholder="Confirm Password"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3" className="d-flex">
                  <Label className="my-auto" htmlFor="profession">
                    Specialty
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <select
                    id="profession"
                    className="custom-select form-control"
                    onChange={this.didSelectSpecialty}
                  >
                    {/* <option selected>{this.state.profession}</option> */}
                    {this.state.specialtyList.map((list, index) => (
                      <option
                        key={index}
                        value={list.name}
                        defaultValue={
                          this.state.profession === list.name ?? true
                        }
                      >
                        {list.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="9">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      id="practiceManager"
                      name="practiceManager"
                      className="form-check-input"
                      value={this.state.practiceManager}
                      checked={this.state.practiceManager}
                      onChange={this.handleCheckBoxChange}
                    />
                    <Label
                      check
                      for="practiceManager"
                      className="form-check-label"
                    >
                      {" "}
                      &nbsp;Practice Manager?
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button
                  size="sm"
                  color="secondary"
                  className="btn btn-outline-dark mr-2"
                  onClick={() => this.setState({ displayModal: true })}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  color="success"
                  className="btn btn-primary"
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
          <ModalComponent
            isOpen={displayModal}
            actionHandler={() => this.props.addUserCompleted()}
            cancelHandler={() => {
              this.setState({ displayModal: false });
            }}
            title="Are you sure you want to cancel without saving?"
          />
        </Card>

        {redirectToDashboard && <Redirect to={{ pathname: "/dashboard" }} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.allUsers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addNewUser, getClinicSpecialities }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNew);
