import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "../../styles/styles.css";

class ClinicProgerss extends Component {
  componentDidMount() {}

  render() {
    var value = (this.props.quota_used / this.props.call_quota) * 100;
    if (value > 100) {
      value = 100;
    }
    value = value.toFixed(2);

    return (
      <div className="call-quota">
        <h5>Monthly Call Time</h5>
        <span>
          Total Minutes: <b>{this.props.call_quota}</b>
        </span>
        <span className="pb-3">
          Used Minutes: <b className="used-minutes">{this.props.quota_used}</b>
        </span>
        <CircularProgressbarWithChildren value={value} className="quota-chart">
          {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
          <div style={{ fontSize: 20, marginTop: -5 }}>
            <strong>{value}%</strong>
          </div>
        </CircularProgressbarWithChildren>

        <small className="pt-3">
          Last updated : {this.props.quota_usage_updated_date}{" "}
        </small>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicProgerss);
