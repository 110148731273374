import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

class Loader extends Component {
  render() {
    return (
      <div className="app-loader">
        <ReactLoading
          type="spin"
          color="black"
          height={60}
          width={60}
          className="app-loader"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
