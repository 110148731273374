import { REGEX_CONSTANTS } from "../constants/Regex";

export function validatePassword(password) {
  let error = "";
  if (!REGEX_CONSTANTS.Password.test(password)) {
    error =
      "Your password must contain a minimum of 8 characters with a combination of uppercase, lowercase, numeric, and special characters.";
  }
  return error;
}

export function validateEmail(email) {
  let error = "";
  if (!REGEX_CONSTANTS.Email.test(email)) {
    error = "Must be a valid email address.";
  }

  return error;
}
