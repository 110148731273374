import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Card,
  Form,
  Alert,
  Input,
  Button,
  CardBody,
  CardGroup,
  Container,
  InputGroup,
} from "reactstrap";

import LoginLogo from "../../../src/assets/img/brand/logo.svg";
import { setNewPassword } from "../../../src/redux/store/actions/UserActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { validatePassword } from "../../utilities/Validator";

import Loader from "../Common/Loader";

class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      password: "",
      confirm_password: "",
      error: "",
      success: "",
      emptyPassError: "",
      emptyConfPassError: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);
    this.setState({ token: parsed.token });
  }

  validateEmailLocally() {
    var isValid = true;

    if (this.state.password === "") {
      this.setState({ emptyPassError: "This is required" });
      isValid = false;
    }
    if (this.state.confirm_password === "") {
      this.setState({ emptyConfPassError: "This is required" });
      isValid = false;
    }

    if (
      this.state.password !== "" &&
      this.state.confirm_password !== "" &&
      this.state.password !== this.state.confirm_password
    ) {
      this.setState({ error: "Passwords do not match." });
      isValid = false;
    }
    if (
      this.state.password !== "" &&
      this.state.confirm_password !== "" &&
      this.state.password === this.state.confirm_password
    ) {
      let response = validatePassword(this.state.password);
      if (response !== "") {
        this.setState({ error: response });
        isValid = false;
      }
    }

    return isValid;
  }

  setPassword = (e) => {
    e.preventDefault();
    this.setState({
      error: "",
      emptyConfPassError: "",
      emptyPassError: "",
      success: "",
    });
    var isValidated = this.validateEmailLocally();

    if (isValidated) {
      this.setState({
        isLoading: true,
        error: null,
      });
      let params = {
        reset_password_token: this.state.token,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
      };

      this.props
        .setNewPassword(params)
        .then(() => {
          this.setState({
            isLoading: false,
            password: "",
            confirm_password: "",
            success:
              "Your Password has been changed successfully. Please login to continue.",
          });
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            error: "Reset Password link has been already used or expired.",
          });
        });
    }
  };

  validatePasswordLocally() {
    let isValidated = true;

    return isValidated;
  }

  render() {
    const {
      error,
      success,
      password,
      isLoading,
      emptyPassError,
      confirm_password,
      emptyConfPassError,
    } = this.state;
    return (
      <div className="app flex-row align-items-center app-login">
        {!!isLoading && <Loader />}
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4 login-card">
                  <CardBody>
                    <Form>
                      <img alt="Clinicall logo" src={LoginLogo} />
                      {success && <Alert color="success">{success}</Alert>}
                      {error ===
                        "Reset Password link has been already used or expired." && (
                        <Alert color="danger">{error}</Alert>
                      )}

                      {error ===
                        "Your password must contain a minimum of 8 characters with a combination of uppercase, lowercase, numeric, and special characters." && (
                        <Alert color="danger">{error}</Alert>
                      )}
                      {error === "Passwords do not match." && (
                        <Alert color="danger">{error}</Alert>
                      )}
                      {success !== "" ? (
                        <Button
                          color="secondary"
                          onClick={() => this.props.history.push("/login")}
                        >
                          Login
                        </Button>
                      ) : (
                        <Fragment>
                          <h5 className="my-3">Set New Password</h5>
                          <InputGroup className={"mb-3"}>
                            <div className="d-flex flex-column width-100">
                              <Input
                                maxLength={15}
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                  this.setState({
                                    password: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </InputGroup>
                          {emptyPassError && (
                            <p className="error-message mt-n3 pt-1 pb-3">
                              {emptyPassError}
                            </p>
                          )}

                          <InputGroup className={"mb-3"}>
                            <div className="d-flex flex-column width-100">
                              <Input
                                maxLength={15}
                                type="password"
                                placeholder="Password confirmation"
                                value={confirm_password}
                                onChange={(e) => {
                                  this.setState({
                                    confirm_password: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </InputGroup>
                          {emptyConfPassError && (
                            <p className="error-message mt-n3 pt-1 pb-3">
                              {emptyConfPassError}
                            </p>
                          )}

                          <Row>
                            <Col xs="10">
                              <Button
                                color="primary"
                                className="px-4"
                                onClick={this.setPassword}
                              >
                                Set Password
                              </Button>
                              <div className="reset-password-divider" />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setNewPassword }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
