import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../styles/styles.css";

import {
  getAppointmentNotes,
  addAppointmentNotes,
  addPublicAppointmentNotes,
  configureAppointmentNotes,
} from "../../../src/redux/store/actions/AppointmentActions";

import { SEND_NOTES_INTERVAL } from "../../constants/GeneralConstants";

class AppointmentNotesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      privateNotes: "",
      patientNotes: "",
      privateNotesId: null,
      patientNotesId: null,
      isPrivateNotesEmpty: true,
      isPatientNotesEmpty: true,
      appointmentId: this.props.appointment.id,
    };
  }

  componentDidMount() {
    this.fetchAppointmentNotes();
    this.privateNotesTimer = setInterval(
      () => this.sendPrivateData(),
      SEND_NOTES_INTERVAL
    );
    this.patientNotesTimer = setInterval(
      () => this.sendPatientData(),
      SEND_NOTES_INTERVAL
    );
  }

  componentWillUnmount() {
    if (this.privateNotesTimer) {
      clearInterval(this.privateNotesTimer);
      this.privateNotesTimer = null;
    }
    if (this.patientNotesTimer) {
      clearInterval(this.patientNotesTimer);
      this.patientNotesTimer = null;
    }
    this.sendPrivateData();
    this.sendPatientData();
  }

  fetchAppointmentNotes = () => {
    this.props
      .getAppointmentNotes(this.state.appointmentId)
      .then((response) => {
        if (response.length > 0) {
          let array = response;
          for (let index = 0; index < array.length; index++) {
            if (array[index].is_private) {
              this.setState({
                isPrivateNotesEmpty: false,
                privateNotes: array[index].note,
                privateNotesId: array[index].id,
              });
            } else if (!array[index].is_private) {
              this.setState({
                isPatientNotesEmpty: false,
                patientNotes: array[index].note,
                patientNotesId: array[index].id,
              });
            }
          }
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  addPatientAppointmentNotesFirstTime = () => {
    let params = {
      appointment_note: { note: this.state.patientNotes, is_private: false },
    };
    this.props
      .configureAppointmentNotes(params, this.state.appointmentId)
      .then((response) => {
        this.setState({
          isLoading: false,
          isPatientNotesEmpty: false,
          patientNotesId: response.id,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  addPatientAppointmentNotes() {
    let params = {
      appointment_note: { note: this.state.patientNotes, is_private: false },
    };
    this.props
      .addAppointmentNotes(
        params,
        this.state.appointmentId,
        this.state.patientNotesId
      )
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  addPatientPublicAppointmentNotes() {
    const { appointmentId, patientNotesId } = this.state;
    this.props
      .addPublicAppointmentNotes(appointmentId, patientNotesId)
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  addPrivateAppointmentNotesFirstTime = () => {
    let params = {
      appointment_note: { note: this.state.privateNotes, is_private: true },
    };
    this.props
      .configureAppointmentNotes(params, this.state.appointmentId)
      .then((response) => {
        this.setState({
          isLoading: false,
          isPrivateNotesEmpty: false,
          privateNotesId: response.id,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  addPrivateAppointmentNotes() {
    let params = {
      appointment_note: { note: this.state.privateNotes, is_private: true },
    };
    this.props
      .addAppointmentNotes(
        params,
        this.state.appointmentId,
        this.state.privateNotesId
      )
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  sharePatientNotes = () => {
    confirmAlert({
      title: "",
      message:
        "This will publish your notes to the client. Are you sure you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.sendPatientPublicData(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  async sendPatientPublicData() {
    const { patientNotes, patientNotesId } = this.state;
    if (patientNotes !== "" && patientNotesId !== null) {
      this.sendPatientData();
      this.addPatientPublicAppointmentNotes();
    }
  }

  async sendPatientData() {
    if (this.state.patientNotes !== " ") {
      if (this.state.isPatientNotesEmpty) {
        this.addPatientAppointmentNotesFirstTime();
      } else {
        this.addPatientAppointmentNotes();
      }
    }
  }

  async sendPrivateData() {
    if (this.state.privateNotes !== " ") {
      if (this.state.isPrivateNotesEmpty) {
        this.addPrivateAppointmentNotesFirstTime();
      } else {
        this.addPrivateAppointmentNotes();
      }
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={12} className="note-block">
            <h4>Private Notes</h4>
            <textarea
              maxLength={500}
              onChange={this.handleChange}
              id="privateNotes"
              name="privateNotes"
              value={this.state.privateNotes}
              placeholder="Private Notes"
            ></textarea>
          </Col>

          <Col sm={12} className="note-block">
            <Row className="align-items-center">
              <Col>
                <h4>Patient Notes</h4>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button onClick={this.sharePatientNotes}>Publish</Button>
              </Col>
            </Row>
            <textarea
              maxLength={500}
              onChange={this.handleChange}
              id="patientNotes"
              name="patientNotes"
              value={this.state.patientNotes}
              placeholder="Patient Notes"
            ></textarea>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAppointmentNotes,
      addAppointmentNotes,
      configureAppointmentNotes,
      addPublicAppointmentNotes,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentNotesPanel);
