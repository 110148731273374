import React, { Component } from "react";
import {
  Form,
  Alert,
  Input,
  Modal,
  Button,
  ModalBody,
  FormGroup,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { sendInvite } from "../../redux/store/actions/InvitationActions";

import Loader from "../Common/Loader";

class InviteClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      inviteEmail: "",

      error: null,
      success: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      error: null,
      isLoading: false,
    });
  }

  componentDidUpdate() {}

  toggle = () => {
    this.setState({
      open: !this.state.open,
      error: null,
      success: null,
      inviteEmail: "",
    });
  };

  handleChange = (event) => {
    this.setState({ inviteEmail: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: null, isLoading: true, success: null });
    let params = {
      invitation: {
        email: this.state.inviteEmail,
      },
    };

    this.props
      .sendInvite(params)
      .then(() => {
        this.props.fetchInvitations(1);
        this.setState({
          success: "Invite successfully sent",
          isLoading: false,
          inviteEmail: "",
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          isLoading: false,
        });
      });
  };

  render() {
    const { buttonLabel, className } = this.props;
    const { open, error, success, inviteEmail, isLoading } = this.state;

    return (
      <div>
        {!!isLoading && <Loader />}
        <Button
          className="btn btn-outline-dark float-right"
          onClick={() => {
            this.toggle();
          }}
        >
          {buttonLabel}
        </Button>
        <Modal isOpen={open} toggle={this.toggle} className={className}>
          <ModalHeader toggle={this.toggle}>Send Invitation</ModalHeader>
          <ModalBody>
            <Form inline onSubmit={this.handleSubmit} className="popup-invite">
              {!!error && <Alert color="danger">{error}</Alert>}
              {!!success && <Alert color="success">{success}</Alert>}

              <FormGroup>
                <Input
                  id="hf-email"
                  className="w-100"
                  name="email"
                  value={inviteEmail}
                  onChange={this.handleChange}
                  placeholder="Email"
                />
              </FormGroup>
              <Button color="danger" disabled={!!isLoading && true}>
                Send
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invitations: state.invitations.invitations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendInvite }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteClients);
