import React, { Component, Suspense, Fragment } from "react";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { AppHeader } from "@coreui/react";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Alert,
  Button,
  CardBody,
  CardGroup,
  Container,
  InputGroup,
} from "reactstrap";

import LoginLogo from "../../../src/assets/img/brand/logo.svg";
import {
  logoutUser,
  resetPassword,
} from "../../../src/redux/store/actions/UserActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { validatePassword } from "../../utilities/Validator";
import StorageConstants from "../../constants/StorageConstants";

import Header from "../Header/Header";
import Loader from "../Common/Loader";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      current_password: "",
      password_confirmation: "",

      error: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
  }

  login = (e) => {
    e.preventDefault();
    this.setState({ error: "" });
    var isValidated = this.validatePasswordLocally();
    if (isValidated) {
      this.setState({
        isLoading: true,
        error: null,
      });
      let params = {
        current_password: this.state.current_password,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      };

      this.props
        .resetPassword(params)
        .then(() => {
          this.setState({ isLoading: false });
          this.props.history.goBack();
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            error: "Unable to reset password.",
          });
        });
    }
  };

  validatePasswordLocally() {
    let isValidated = true;
    if (
      this.state.current_password === "" ||
      this.state.password === "" ||
      this.state.password_confirmation === ""
    ) {
      this.setState({ error: "Please fill all the fields." });
      isValidated = false;
    } else if (this.state.password !== this.state.password_confirmation) {
      this.setState({ error: "Passwords do not match." });
      isValidated = false;
    } else {
      let response = validatePassword(this.state.password);
      if (response !== "") {
        this.setState({ error: response });
        isValidated = false;
      }
    }

    return isValidated;
  }

  goToSettings = () => {
    this.props.history.push(ROUTE_CONSTANTS.SETTINGS);
  };

  goToResetPasswordScreen = () => {
    this.props.history.push(ROUTE_CONSTANTS.RESET_PASSWORD);
  };

  logOut = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
      })
      .catch(() => {});
  };

  render() {
    const { user } = this.props;
    const { current_password, password, password_confirmation } = this.state;
    const error = get(user, ["loggedin", "loginAction", "message"]);
    const isLoggingIn = get(user, ["loggedin", "isLoggingIn"]);

    return (
      <Fragment>
        <AppHeader fixed>
          <Suspense>
            <Header
              hideResetOption={true}
              goToSettings={this.goToSettings}
              logOut={this.logOut}
              // goToResetPasswordScreen={this.goToResetPasswordScreen}
            />
          </Suspense>
        </AppHeader>
        <div className="app flex-row align-items-center app-login">
          {this.state.isLoading && <Loader />}
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 login-card">
                    <CardBody>
                      <Form error={!!error} disabled={isLoggingIn}>
                        <img alt="Clinicall logo" src={LoginLogo} />
                        {error && (
                          <Alert color="danger">
                            <h4 className="alert-heading"> Login Failed</h4>
                            <hr />
                            <p className="mb-0">{error}</p>
                          </Alert>
                        )}
                        {this.state.error === "Passwords do not match." && (
                          <Alert color="danger">{this.state.error}</Alert>
                        )}
                        {this.state.error === "Unable to reset password." && (
                          <Alert color="danger">{this.state.error}</Alert>
                        )}

                        {this.state.error === "Password is not valid" && (
                          <Alert color="danger">{this.state.error}</Alert>
                        )}

                        {this.state.error ===
                          "Your password must contain a minimum of 8 characters with a combination of uppercase, lowercase, numeric, and special characters." && (
                          <Alert color="danger">{this.state.error}</Alert>
                        )}
                        <h5 className="my-3">Reset Password</h5>

                        <InputGroup className={"mb-3"}>
                          <div className="d-flex flex-column width-100">
                            <Input
                              type="password"
                              maxLength={15}
                              placeholder="Current password"
                              value={current_password}
                              onChange={(e) => {
                                this.setState({
                                  current_password: e.target.value,
                                  error: null,
                                });
                              }}
                            />
                          </div>
                        </InputGroup>
                        {!!this.state.error && isEmpty(current_password) && (
                          <p className="error-message mt-n3 pt-1 pb-3">
                            This is required
                          </p>
                        )}

                        <InputGroup className="mb-3">
                          <div className="d-flex flex-column width-100">
                            <Input
                              type="password"
                              maxLength={15}
                              placeholder="New password"
                              autoComplete="current-password"
                              value={password}
                              onChange={(e) => {
                                this.setState({
                                  password: e.target.value,
                                  error: null,
                                });
                              }}
                            />
                          </div>
                        </InputGroup>
                        {!!this.state.error && isEmpty(password) && (
                          <p className="error-message mt-n3 pt-1 pb-3">
                            This is required
                          </p>
                        )}
                        <InputGroup className="mb-4">
                          <div className="d-flex flex-column width-100">
                            <Input
                              type="password"
                              maxLength={15}
                              placeholder="Password confirmation"
                              autoComplete="current-password"
                              value={password_confirmation}
                              onChange={(e) => {
                                this.setState({
                                  password_confirmation: e.target.value,
                                  error: null,
                                });
                              }}
                            />
                          </div>
                        </InputGroup>
                        {!!this.state.error &&
                          isEmpty(password_confirmation) && (
                            <p className="error-message mt-n4 pt-1 pb-3">
                              This is required
                            </p>
                          )}
                        <Row>
                          <Col xs="10">
                            <Button
                              color="primary"
                              className="px-4"
                              onClick={this.login}
                            >
                              Reset Password
                            </Button>
                            <div className="reset-password-divider" />
                            <Button
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetPassword, logoutUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
