import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
import {
    Row,
    Col,
    Label,
    Modal,
    Alert,
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";

import "../../styles/styles.css";

import {
    getAppointmentReasons,
    setAppointmentAssignee
} from "../../../src/redux/store/actions/AppointmentActions";

import {
    getUsers,
} from "../../../src/redux/store/actions/UserActions";


import Loader from "../Common/Loader";

class AppointmentAssignee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: null,
            isLoading: false,
            isSubmitting: false,
            isSubmissionError: false,
            userList: [],
            selectedUser: null,
            appointment: this.props.appointment

        };
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = (num, sortBy = "") => {
        this.props
            .getUsers(1, sortBy)
            .then((response) => {
                let list = response.list.filter((p) => p.is_approved != false);
                this.setState({ userList: list })
            })
            .catch((err) => {

            });
    };


    reasonHandler = (event) => {
        this.setState({ selectedUser: event.target.value });
    };

    handleSumbit = () => {

        let params = { doctor_id: this.state.selectedUser }

        this.setState({ isSubmitting: true, success: null, error: null });
        this.props
            .setAppointmentAssignee(this.state.appointment.id, params)
            .then((res) => {
                let msg = "Appointment has been successfully assigned.";
                this.setState({ isSubmitting: false, success: msg });
                this.props.submissionHandler(msg);
            })
            .catch((error) => {
                this.setState({ isSubmitting: false, isSubmissionError: true, error: "Unable to assign appointment." });

            });
    };



    render() {
        const {
            userList,
            isSubmitting,
            isLoading,
            error,
            success,
            appointment
        } = this.state;

        const { open, toggle } = this.props;

        if (isLoading) return <Loader />;
        return (


            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>Appointment Assignment</ModalHeader>
                {!!error && (
                    <Alert color="danger">{error}</Alert>
                )}
                {!!success && (
                    <Alert color="success">
                        {success}
                    </Alert>
                )}
                <ModalBody>

                    <Row className="mb-3">
                        <Col sm={3} className="d-flex align-items-center">
                            <Label className="m-0">Users</Label>
                        </Col>
                        <Col sm={9}>
                            <select
                                className="custom-select form-control"
                                id="hf-profession"
                                onChange={this.reasonHandler}
                            >
                                {userList.map((user, index) => (
                                    <option value={user.id} key={`list-${index}`} selected={appointment.doctor_name == user.name && true} >
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button
                        className="btn btn-outline-dark"
                        disabled={!!isSubmitting && true}
                        onClick={toggle}
                    >
                        Cancel
          </Button>
                    <Button
                        className="btn btn-primary"
                        onClick={this.handleSumbit}
                        disabled={!!isSubmitting && true}
                    >
                        {!isSubmitting ? "Save" : "Saving..."}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsers,
            getAppointmentReasons,
            setAppointmentAssignee
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentAssignee);
