import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Table,
  Alert,
  CardBody,
  Pagination,
  CardHeader,
} from "reactstrap";

import {
  cancelInvite,
  getAllInvites,
} from "../../redux/store/actions/InvitationActions";
import { timeConversion } from "../../utilities/TimeZone";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { DATE_TIME_FORMAT } from "../../constants/GeneralConstants";

import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";
import PaginateBar from "../Common/Pagination";

import InviteClients from "./InviteClients";

const sortingDefaultState = {
  asecDate: false,
  descDate: false,
  asecEmail: false,
  descEmail: false,
};
class InvitationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      isLoading: false,
      displayModal: false,
      sortedBy: {
        asecDate: false,
        descDate: false,
        asecEmail: false,
        descEmail: false,
      },

      sortingBy: "",
      totalCount: 1,
      currentPage: 1,
      invitations: [],
      isSortedByDate: false,
      selectedInvitation: null,
    };
  }

  componentDidMount() {
    const { currentPage } = this.state;
    this.fetchInvitations(currentPage);
  }

  fetchInvitations = (num, sortBy = "") => {
    this.setState({ isLoading: true });
    this.props
      .getAllInvites(num, sortBy)
      .then((response) => {
        this.setState({
          isLoading: false,
          totalCount: response.total_count,
          invitations: response.list,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLoading: false });
      });
  };

  sendCanceInviteCall = () => {
    this.setState({ isLoading: true });
    this.props
      .cancelInvite(this.state.selectedInvitation.id)
      .then(() => {
        const { currentPage } = this.state;
        this.fetchInvitations(currentPage > 1 ? currentPage - 1 : 1);
        this.setState({
          success: true,
          isLoading: false,
          displayModal: false,
        });
      })
      .catch(() => {
        this.setState({ displayModal: false, isLoading: false, error: true });
      });
  };

  cancelButtonHandler = (id) => {
    this.setState({ selectedInvitation: id, displayModal: true });
  };

  sortArrayByEmail = () => {
    let pageNum = 1;
    let criteria = "email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecEmail: true,
      },
    });
    this.fetchInvitations(pageNum, criteria);
  };

  sortArrayByDescEmail = () => {
    let pageNum = 1;
    let criteria = "-email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descEmail: true,
      },
    });
    this.fetchInvitations(pageNum, criteria);
  };

  sortArrayWithDate = () => {
    let pageNum = 1;
    let criteria = "created_at";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecDate: true,
      },
    });
    this.fetchInvitations(pageNum, criteria);
  };

  sortArrayWithDescDate = () => {
    let pageNum = 1;
    let criteria = "-created_at";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descDate: true,
      },
    });
    this.fetchInvitations(pageNum, criteria);
  };

  getPageData = (num) => {
    const { sortingBy } = this.state;
    this.setState({ currentPage: num });
    this.fetchInvitations(num, sortingBy);
  };

  render() {
    const {
      error,
      success,
      sortedBy,
      isLoading,
      totalCount,
      invitations,
      currentPage,
      displayModal,
    } = this.state;
    const { asecEmail, descEmail, asecDate, descDate } = sortedBy;
    return (
      <div className="animated fadeIn">
        {!!isLoading && <Loader />}
        {!!error && (
          <Alert color="danger">Couldn't cancel the invitation.</Alert>
        )}
        {!!success && (
          <Alert color="success">
            Invitation has been successfully cancelled.
          </Alert>
        )}

        <Card className="mb-0 border-0 border-radius-0 mb-5">
          <CardHeader>
            <Row>
              <Col sm={7} className="d-flex align-items-center">
                <i className="fa fa-align-justify"></i>
                <h2>Invitations</h2>
              </Col>
              <Col sm={5} className="mt-2 mt-sm-0">
                <InviteClients
                  buttonLabel="Invite"
                  fetchInvitations={this.fetchInvitations}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Email</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByEmail}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecEmail && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescEmail}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descEmail && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col">Invitation Code</th>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Invited At</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayWithDate}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecDate && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayWithDescDate}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descDate && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {invitations.length > 0 &&
                  invitations.map((invitation) => (
                    <tr key={invitation.id.toString()}>
                      <td>{invitation.email}</td>
                      <td>{invitation.code}</td>
                      <td>
                        {moment(invitation.invited_at).format(
                          DATE_TIME_FORMAT.DATE
                        )}
                        &nbsp; - &nbsp;{timeConversion(invitation.invited_at)}
                      </td>
                      <td>
                        <button
                          className="btn btn-secondary"
                          onClick={() => this.cancelButtonHandler(invitation)}
                        >
                          Cancel
                        </button>
                      </td>
                    </tr>
                  ))}
                {invitations.length === 0 && (
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td>No Record Found</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {totalCount / 15 > 1 && (
              <Pagination className="pagination-wrapper">
                <PaginateBar
                  pageNum={currentPage}
                  changePageNo={this.getPageData}
                  totalPages={Math.ceil(totalCount / 15)}
                />
              </Pagination>
            )}
          </CardBody>
          <ModalComponent
            isOpen={displayModal}
            actionHandler={() => {
              this.sendCanceInviteCall();
            }}
            cancelHandler={() => {
              this.setState({ displayModal: false });
            }}
            title="Are you sure you want to cancel Invitation?"
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getAllInvites, cancelInvite }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvitationsList));
