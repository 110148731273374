import { success } from "./index";
import { CLIENTS_ACTIONS } from "../../../../src/constants/ActionConstants";
import ClientService from "../../services/ClientService";

export function getClients(pageNum, sortBy) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClientService.getClients(pageNum, sortBy);
        dispatch(success(CLIENTS_ACTIONS.CLIENTS_SUCCESS, response, dispatch));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}


export function addNewClient(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ClientService.addNewClient(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}