import React, { Suspense } from "react";
import { connect } from "react-redux";
import * as router from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";

import { logoutUser } from "../../../src/redux/store/actions/UserActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import StorageConstants from "../../constants/StorageConstants";
import { USER_COMPONENTS } from "../../constants/GeneralConstants";

import staffNav from "../../StaffNavigation";
import navigation from "../../PracticeMangNav";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import UserNew from "./UserNew";
import UserEdit from "./UserEdit";
import UserListing from "./UserListing";

class Users extends React.Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      selectedUser: null,
      componentToShow: USER_COMPONENTS.USERS_LISTING,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
  }

  goToNewUser = () => {
    this.setState({
      componentToShow: USER_COMPONENTS.USER_NEW,
    });
  };

  goToEditUser = (user) => {
    this.setState({
      componentToShow: USER_COMPONENTS.USERS_EDIT,
      selectedUser: user,
    });
  };

  addUserCompleted = () => {
    this.setState({
      componentToShow: USER_COMPONENTS.USERS_LISTING,
    });
  };

  goToSettings = () => {
    this.props.history.push(ROUTE_CONSTANTS.SETTINGS);
  };

  goToResetPasswordScreen = () => {
    this.props.history.push(ROUTE_CONSTANTS.RESET_PASSWORD);
  };

  logOut = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
      })
      .catch(() => { });
  };

  alertHandler = () => {
    this.setState({
      success: !this.state.success,
    });
  };

  render() {
    let nav =
      localStorage.getItem(StorageConstants.LOGGED_IN_USER_ROLE) === "3"
        ? staffNav
        : navigation;

    const { success } = this.state;
    const { match } = this.props;
    return (
      <React.Fragment>
        <AppSidebar fixed display="lg">
          <Suspense>
            <AppSidebarNav navConfig={nav} {...{ match }} router={router} />
            {this.props.clinic && (
              <figure className="navbar-logo">
                <img
                  src={this.props.clinic.attachment_url}
                  alt="clinic-icon"
                />
              </figure>
            )}
          </Suspense>
          <AppSidebarMinimizer />
        </AppSidebar>

        <main className="main">
          <AppHeader fixed>
            <Suspense>
              <Header
                goToSettings={this.goToSettings}
                logOut={this.logOut}
                goToResetPasswordScreen={this.goToResetPasswordScreen}
              />
            </Suspense>
          </AppHeader>

          <div className="animated fadeIn">
            {this.state.componentToShow === USER_COMPONENTS.USERS_LISTING && (
              <UserListing
                success={success}
                goToNewUser={this.goToNewUser}
                goToEditUser={this.goToEditUser}
              />
            )}
            {this.state.componentToShow === USER_COMPONENTS.USER_NEW && (
              <UserNew
                alertHandler={this.alertHandler}
                addUserCompleted={this.addUserCompleted}
              />
            )}
            {this.state.componentToShow === USER_COMPONENTS.USERS_EDIT && (
              <UserEdit
                selectedUser={this.state.selectedUser}
                editUserCompleted={this.addUserCompleted}
              />
            )}
          </div>
        </main>

        <AppFooter fixed>
          <Suspense>
            <Footer />
          </Suspense>
        </AppFooter>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinic: state.clinic.clinic,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logoutUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
