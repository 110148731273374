import { APPOINTMENT_ACTIONS } from "../../../constants/ActionConstants";

const initialState = {
  appointments: [],
  appointmentHistory: [],
};

const userAppointment = (state = initialState, action) => {
  switch (action.type) {
    case APPOINTMENT_ACTIONS.APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };

    case APPOINTMENT_ACTIONS.APPOINTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        appointmentHistory: action.payload,
      };

    default:
      return state;
  }
};

export default userAppointment;
