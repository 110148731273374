import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./api/GenericResponseHandler";
import { ApiClient } from "./api/ApiClient";
import StorageConstants from "../../constants/StorageConstants";

export function getAllInvites(pageNum, sortBy) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_INVITATION}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":pageNum", pageNum);
  if (sortBy === "created_at") {
    url = url + "&sort=created_at";
  }
  if (sortBy === "email") {
    url = url + "&sort=email";
  }
  if (sortBy === "-created_at") {
    url = url + "&sort=-created_at";
  }
  if (sortBy === "-email") {
    url = url + "&sort=-email";
  }
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function sendInvite(params) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.SEND_INVITATION}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .post(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function cancelInvite(inviteId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.CANCEL_INVITATION}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":inviteId", inviteId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).delete(url).then(handleResponse).catch(handleError);
}

export default {
  getAllInvites,
  sendInvite,
  cancelInvite,
};
