import React from "react";
import { PaginationItem, PaginationLink } from "reactstrap";

function formatNumber(number) {
  if (!number) return number;
  const tempNum = number.toString();
  const tempNo = tempNum.indexOf(".");
  return tempNum.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (item, index) => {
    return tempNo < 0 || index < tempNo ? item + "," : item;
  });
}

function PaginatedLink(props) {
  let display = props.children;
  if (props.addLink) {
    display = (
      <PaginationItem>
        <PaginationLink
          tag="button"
          className="acitve-pagination"
          onClick={() => props.changePageNo(props.linkTo)}
        >
          {display}
        </PaginationLink>
      </PaginationItem>
    );
  }
  if (!props.addLink) {
    display = (
      <PaginationItem active>
        <PaginationLink
          tag="button"
          className="in-acitve-pagination"
          onClick={() => props.changePageNo(props.linkTo)}
        >
          {display}
        </PaginationLink>
      </PaginationItem>
    );
  }
  return display;
}

function NumLink(props) {
  const { pageNum, linkNum, changePageNo } = props;
  return (
    <PaginatedLink
      addLink={pageNum !== linkNum}
      linkTo={linkNum}
      changePageNo={changePageNo}
    >
      {formatNumber(linkNum)}
    </PaginatedLink>
  );
}

function PrevLink(props) {
  const { pageNum, changePageNo } = props;
  return (
    <PaginatedLink
      addLink={pageNum > 1}
      linkTo={pageNum - 1}
      changePageNo={changePageNo}
    >
      Prev
    </PaginatedLink>
  );
}

function NextLink(props) {
  const { pageNum, totalPages, changePageNo } = props;
  return (
    <PaginatedLink
      addLink={pageNum < totalPages}
      linkTo={pageNum + 1}
      changePageNo={changePageNo}
    >
      Next
    </PaginatedLink>
  );
}

function ProductListingNavigation(props) {
  const { pageNum, totalPages } = props;
  function getNumLinks() {
    const numLinks = [];
    numLinks.push(<NumLink key={0} linkNum={1} {...props} />);
    if (totalPages > 1) {
      let start = pageNum - 1;
      if (start < 2) {
        start = 2;
      } else if (start >= 4 && pageNum === totalPages) {
        start -= 2;
      } else if (
        start >= 3 &&
        (pageNum + 1 === totalPages || pageNum === totalPages)
      ) {
        start--;
      }
      let end = pageNum + 1;
      if (end < 4) {
        end = 4;
      }
      if (end > totalPages) {
        end = totalPages;
      }

      if (start > 2) {
        numLinks.push(
          <li className="list-dots" key={-1}>
            ...
          </li>
        );
      }
      for (let inc = start; inc <= end; inc++) {
        numLinks.push(<NumLink key={2 * inc} linkNum={inc} {...props} />);
      }
      if (end < totalPages - 1) {
        numLinks.push(
          <li className="list-dots" key={-2}>
            ...
          </li>
        );
      }
      if (end < totalPages) {
        numLinks.push(<NumLink key={-3} linkNum={totalPages} {...props} />);
      }
    }
    return numLinks;
  }

  if (pageNum <= 0 || pageNum > totalPages || !totalPages) {
    return null;
  }

  return (
    <>
      {totalPages > 20 && <PrevLink {...props} />}
      {getNumLinks()}
      {totalPages > 20 && <NextLink {...props} />}
    </>
  );
}

export default ProductListingNavigation;
