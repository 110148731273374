import { combineReducers } from 'redux';
import user from './user';
import appointment from './appointment';
import invitations from './invitation';
import clients from './clients';
import clinic from './clinic';

export default combineReducers({
    user,
    appointment,
    clients,
    invitations,
    clinic
});