import React, { Component } from "react";
import { Modal, ModalBody, Button, ModalFooter } from "reactstrap";

class ModalComponent extends Component {
  render() {
    const {
      title,
      isOpen,
      actionHandler,
      cancelHandler,
      buttonLabel = "Confirm",
    } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalBody>{title}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={actionHandler}>
            {buttonLabel}
          </Button>
          <Button color="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalComponent;
