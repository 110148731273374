import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Alert } from "reactstrap";
import VideoComponent from "./VideoComponent";
import { AppHeader, AppFooter } from "@coreui/react";
import { endAppointmentCall } from "../../../src/redux/store/actions/AppointmentActions";
import "../../styles/styles.css";
import { ROUTE_CONSTANTS } from "../../constants/Routes";
import StorageConstants from "../../constants/StorageConstants";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AppointmentNotesPanel from "./AppointmentNotesPanel";

class AppointmentRoom extends Component {
  constructor(props) {
    super(props);
    const appointment = this.props.location.appointment;
    this.state = {
      appointment: appointment,
      loading: true,
      error: false,
      success: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
  }

  isRoomProvided = () => {
    const { appointment } = this.state;
    return appointment && appointment.identity;
  };

  goToAppointment = (isAppointmentEnd) => {
    if (isAppointmentEnd) {
      const { appointment } = this.state;
      this.setState({ loading: true });
      this.props
        .endAppointmentCall(appointment.id)
        .then(() => {
          this.setState({ loading: false, success: true });
          this.props.history.goBack();
        })
        .catch(() => {
          this.setState({ loading: false, error: true });
        });
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const { appointment, loading, success, error } = this.state;
    return (
      <main className="main start-appointment">
        <AppHeader fixed>
          <Suspense>
            <Header showRightMenu={true} />
          </Suspense>
        </AppHeader>
        {!!error && <Alert color="danger">Couldn't end the call.</Alert>}
        {!!success && (
          <Alert color="success">Call has been ended successfully.</Alert>
        )}
        <Row className="m-0">
          <Col sm={8} md={9} className="p-0">
            <VideoComponent
              data={appointment}
              endVideoCall={this.goToAppointment}
            />
          </Col>
          <Col sm={4} md={3} className="note-sidebar">
            <AppointmentNotesPanel appointment={this.state.appointment} />
          </Col>
        </Row>


        {!loading &&
          !this.state.appointment.identity &&
          "Chat Room not available!"}

        <AppFooter fixed>
          <Suspense>
            <Footer />
          </Suspense>
        </AppFooter>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointments: state.appointment.appointments,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ endAppointmentCall }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentRoom);
