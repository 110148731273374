import { request, success } from "./index";
import UserService from "../../services/UserService";
import StorageConstants from "../../../constants/StorageConstants";

import {
  USER_DELETE,
  USER_ACTIONS,
  USER_ASSIGNEE,
  GET_USER_ACTIONS,
} from "../../../../src/constants/ActionConstants";

export function loginUser(data) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      dispatch(request(USER_ACTIONS.LOGIN_REQUEST, "", dispatch));
      try {
        const response = await UserService.login(data);
        dispatch(success(USER_ACTIONS.LOGIN_SUCCESS, response, dispatch));
        localStorage.setItem(StorageConstants.CLINIC_ID, response.clinic_id);
        localStorage.setItem(StorageConstants.LOGGED_IN_USER, response.name);
        localStorage.setItem(
          StorageConstants.LOGGED_IN_USER_ROLE,
          response.role_id
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function resetPassword(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      dispatch(request(USER_ACTIONS.LOGIN_REQUEST, "", dispatch));
      try {
        const response = await UserService.resetPassword(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function sendForgotPassword(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.sendForgotPassword(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function setNewPassword(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.setNewPassword(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getUsers(pageNum, sortBy) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.getUsers(pageNum, sortBy);
        dispatch(
          success(GET_USER_ACTIONS.GET_USER_SUCCESS, response, dispatch)
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function setUserAssignee(id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.setUserAssignee(id);
        dispatch(
          success(USER_ASSIGNEE.USER_ASSIGNEE_SUCCESS, response, dispatch)
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function deleteUser(id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.deleteUser(id);
        dispatch(success(USER_DELETE.USER_DELETE_SUCCESS, response, dispatch));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function approveUser(ids) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.approveUser(ids);
        dispatch(success(USER_DELETE.USER_APPROVE_SUCCESS, response, dispatch));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function addNewUser(params) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.addNewUser(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function editUser(params, id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.editUser(params, id);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getSelectedUser(id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.getSelectedUser(id);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function logoutUser(id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await UserService.logoutUser();
        localStorage.removeItem(StorageConstants.CLINIC_ID);
        localStorage.removeItem(StorageConstants.AUTH_TOKEN);
        localStorage.removeItem(StorageConstants.LOGGED_IN_USER);
        localStorage.removeItem(StorageConstants.LOGGED_IN_USER_ROLE);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
