import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Table,
  Label,
  Button,
  CardBody,
  CardHeader,
  Pagination,
} from "reactstrap";

import {
  appointmentEndTimeWithTimeZone,
  appointmentStartTimeWithTimeZone,
} from "../../../utilities/TimeZone";
import {
  DATE_TIME_FORMAT,
  APPOINTMENT_STATUSES,
  APPOINTMENT_STATUSES_LIST,
} from "../../../constants/GeneralConstants";
import { ROUTE_CONSTANTS } from "../../../constants/Routes";
import {
  getUserAppointmentLogs,
  getUserAppointmentHistory,
} from "../../../redux/store/actions/AppointmentActions";
import "../../../styles/styles.css";

import Loader from "../../Common/Loader";
import PaginateBar from "../../Common/Pagination";

import AppointmentNotes from "../AppointmentNotes";

import AppointmentLogs from "./AppointmentLogs";

const sortingDefaultState = {
  asecTime: false,
  descTime: false,
  asecDate: false,
  descDate: false,
  asecPatientName: false,
  descPatientName: false,
};

class PastAppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      isModalOpen: false,
      isLogsLoading: false,
      sortedBy: {
        asecTime: false,
        descTime: false,
        asecDate: false,
        descDate: false,
        asecPatientName: false,
        descPatientName: false,
      },

      sortingBy: "",
      totalCount: 1,
      currentPage: 1,
      appointmentLogs: [],
      appointmentHistory: [],

      showAppointment: false,
      selectedAppointment: null,
      selectedFilter: APPOINTMENT_STATUSES.ALL,
    };
  }

  componentDidMount() {
    const { selectedFilter, currentPage } = this.state;
    this.fetchPastAppointments(selectedFilter, currentPage);
  }

  fetchPastAppointments = (status, num, sortBy = "") => {
    this.setState({ isLoading: true, selectedFilter: status });
    this.props
      .getUserAppointmentHistory(status, num, sortBy)
      .then((response) => {
        this.setState({
          isLoading: false,
          totalCount: response.total_count,
          appointmentHistory: response.list,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLoading: false });
      });
  };

  fetchAppointmentLogs = (appointment) => {
    this.setState({ isLogsLoading: true, appointmentLogs: [] });
    this.props
      .getUserAppointmentLogs(appointment)
      .then((response) => {
        this.setState({
          isLogsLoading: false,
          appointmentLogs: response,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLogsLoading: false, appointmentLogs: [] });
      });
  };

  didChangeAppointmentStatus = () => {
    var event = document.getElementById("status");
    let item = APPOINTMENT_STATUSES_LIST.filter((p) => p.key === event.value);
    this.setState({ selectedFilter: item[0].value, currentPage: 1 });
    this.fetchPastAppointments(item[0].value, 1);
  };

  sortArrayWithDate = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecDate: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  sortArrayWithDescDate = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "-appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descDate: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  sortArrayWithTime = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecTime: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  sortArrayWithDescTime = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "-appointment_time";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descTime: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  sortArrayPatientName = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecPatientName: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  sortArrayDescPatientName = () => {
    const { selectedFilter } = this.state;
    let pageNum = 1;
    let criteria = "-name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descPatientName: true,
      },
    });
    this.fetchPastAppointments(selectedFilter, pageNum, criteria);
  };

  selectNotes = (appointment) => {
    this.setState({ selectedAppointment: appointment, showAppointment: true });
  };

  closeNotesModal = () => {
    this.setState({ selectedAppointment: null, showAppointment: false });
  };

  displayLogs = (appointment) => {
    this.setState({ isModalOpen: true });
    this.fetchAppointmentLogs(appointment);
  };

  cancelLogs = () => {
    this.setState({ isModalOpen: false });
  };

  getPageData = (num) => {
    const { selectedFilter, sortingBy } = this.state;
    this.setState({ currentPage: num });
    this.fetchPastAppointments(selectedFilter, num, sortingBy);
  };

  render() {
    const {
      sortedBy,
      isLoading,
      totalCount,
      currentPage,
      isModalOpen,
      isLogsLoading,
      appointmentLogs,
      showAppointment,
      appointmentHistory,
      selectedAppointment,
    } = this.state;
    const {
      asecTime,
      descTime,
      asecDate,
      descDate,
      asecPatientName,
      descPatientName,
    } = sortedBy;
    return (
      <Card className="mb-0 border-0 border-radius-0 mb-5">
        <CardHeader className="">
          <Row>
            <Col sm={6} className="d-flex align-items-center">
              <h2>Past Appointments</h2>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={2} className="d-none d-md-block"></Col>
                <Col
                  sm={2}
                  md={4}
                  className="d-flex align-items-center justify-content-md-end pt-2 pt-sm-0"
                >
                  Filter:
                </Col>
                <Col sm={10} md={6}>
                  <select
                    className="custom-select form-control"
                    id="status"
                    onChange={this.didChangeAppointmentStatus}
                  >
                    {APPOINTMENT_STATUSES_LIST.map((list, index) => (
                      <option value={list.key} key={index}>
                        {list.value}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {showAppointment && (
            <AppointmentNotes
              appointment={selectedAppointment}
              closeNotesModal={this.closeNotesModal}
            />
          )}
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col" className="m-0">
                  <div className="flex-centered">
                    <span>Client</span>
                    <span className="d-flex flex-column">
                      <i
                        onClick={this.sortArrayPatientName}
                        className={`fa fa-caret-up fa-lg pointer ${
                          asecPatientName && "active-sorting"
                        }`}
                      ></i>
                      <i
                        onClick={this.sortArrayDescPatientName}
                        className={`fa fa-caret-down fa-lg pointer ${
                          descPatientName && "active-sorting"
                        }`}
                      ></i>
                    </span>
                  </div>
                </th>
                <th>Assigned Staff</th>
                <th scope="col">Purpose</th>
                <th scope="col" className="m-0">
                  <div className="flex-centered m-0">
                    <span>Date</span>
                    <span className="d-flex flex-column">
                      <i
                        onClick={this.sortArrayWithDate}
                        className={`fa fa-caret-up fa-lg pointer ${
                          asecDate && "active-sorting"
                        }`}
                      ></i>
                      <i
                        onClick={this.sortArrayWithDescDate}
                        className={`fa fa-caret-down fa-lg pointer ${
                          descDate && "active-sorting"
                        }`}
                      ></i>
                    </span>
                  </div>
                </th>
                <th scope="col" className="m-0">
                  <div className="flex-centered m-0">
                    <span>Time</span>
                    <span className="d-flex flex-column">
                      <i
                        onClick={this.sortArrayWithTime}
                        className={`fa fa-caret-up fa-lg pointer ${
                          asecTime && "active-sorting"
                        }`}
                      ></i>
                      <i
                        onClick={this.sortArrayWithDescTime}
                        className={`fa fa-caret-down fa-lg pointer ${
                          descTime && "active-sorting"
                        }`}
                      ></i>
                    </span>
                  </div>
                </th>
                <th scope="col">Status</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              {appointmentHistory.length > 0 &&
                appointmentHistory.map((appointment) => (
                  <tr key={appointment.id.toString()}>
                    <td>{appointment.patient_name}</td>
                    <td>{appointment.doctor_name}</td>
                    <td>{appointment.reason_name}</td>
                    <td>
                      {moment(appointment.appointment_localtime).format(
                        DATE_TIME_FORMAT.DATE
                      )}
                    </td>
                    <td>
                      {appointmentStartTimeWithTimeZone(appointment) +
                        " - " +
                        appointmentEndTimeWithTimeZone(appointment)}
                    </td>
                    <td>
                      <Label className="appointment-button">
                        {appointment.appointment_status}
                      </Label>
                      {appointment.appointment_status ===
                        APPOINTMENT_STATUSES.COMPLETED && (
                        <Button
                          onClick={() => {
                            this.displayLogs(appointment.id);
                          }}
                          className="has-icon no-border pl-2"
                        >
                          <i className="ficon-history"></i>
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button
                        disabled={
                          appointment.appointment_status !==
                            APPOINTMENT_STATUSES.COMPLETED && true
                        }
                        onClick={() => {
                          this.selectNotes(appointment);
                        }}
                        className="has-icon no-border"
                      >
                        <i className="ficon-file-alt11"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              {appointmentHistory.length === 0 && (
                <tr>
                  <th scope="row"></th>
                  <td></td>
                  <td></td>
                  <td>No Record Found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          {totalCount / 15 > 1 && (
            <Pagination className="pagination-wrapper">
              <PaginateBar
                pageNum={currentPage}
                changePageNo={this.getPageData}
                totalPages={Math.ceil(totalCount / 15)}
              />
            </Pagination>
          )}
          {!!isLoading && <Loader />}
          {!!isModalOpen && (
            <AppointmentLogs
              isModalOpen={isModalOpen}
              cancelLogs={this.cancelLogs}
              isLogsLoading={isLogsLoading}
              appointmentLogs={appointmentLogs}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getUserAppointmentHistory, getUserAppointmentLogs },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PastAppointmentList));
