import { success } from "./index";

import AppointmentService from "../../services/AppointmentService";
import { APPOINTMENT_ACTIONS } from "../../../../src/constants/ActionConstants";

export function getUserAppointments(pageNum, sortBy) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getUserAppointments(pageNum, sortBy);
        dispatch(
          success(APPOINTMENT_ACTIONS.APPOINTMENT_SUCCESS, response, dispatch)
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getAppointmentNotes(appId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getAppointmentNotes(appId);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function configureAppointmentNotes(params, appId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.configureAppointmentNotes(
          params,
          appId
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function addAppointmentNotes(params, appId, noteId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.addAppointmentNotes(
          params,
          appId,
          noteId
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function addPublicAppointmentNotes(appId, noteId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.addPublicAppointmentNotes(
          appId,
          noteId
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function cancelUserAppointment(appId) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.cancelUserAppointment(appId);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getUserAppointmentHistory(stauts, pageNum, sortBy) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getUserAppointmentHistory(
          stauts,
          pageNum,
          sortBy
        );
        dispatch(
          success(
            APPOINTMENT_ACTIONS.APPOINTMENT_HISTORY_SUCCESS,
            response,
            dispatch
          )
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getUserAppointmentLogs(appointmentId) {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getUserAppointmentLogs(
          appointmentId
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function startAppointment(id) {
  return function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.startAppointment(id);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getAvailableDates() {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getAvailableDates();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getAvailableAppointments(startDate, EndDate) {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getAvailableAppointments(
          startDate,
          EndDate
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getAppointmentReasons() {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.getAppointmentReasons();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function scheduleAppointment(id, params) {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.scheduleAppointment(
          id,
          params
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function endAppointmentCall(id) {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.endAppointmentCall(id);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function setAppointmentAssignee(id, params) {
  return function () {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AppointmentService.setAppointmentAssignee(id, params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
