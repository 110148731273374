import React, { Component } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Alert,
  Modal,
  Label,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "react-calendar/dist/Calendar.css";

import {
  getAppointmentNotes,
  addPublicAppointmentNotes,
} from "../../../src/redux/store/actions/AppointmentActions";

import "../../styles/styles.css";
import { DATE_TIME_FORMAT } from "../../constants/GeneralConstants";
import {
  appointmentEndTimeWithTimeZone,
  appointmentStartTimeWithTimeZone,
} from "../../utilities/TimeZone";

class AppointmentNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      open: true,
      purpose: "",
      error: null,
      privateNotesUpdatedBy: "",
      patientotesUpdatedBy: "",
      publicNotesUpdatedBy: "",
      privateNotes: "",
      patientNotes: "",
      isLoading: false,
      patientNotesId: null,
      isPublishingError: false,
      isPublishingSuccess: false,
      selectedAppointment: this.props.appointment,
    };
  }

  componentDidMount() {
    this.fetchAppointmentNotes();
  }

  fetchAppointmentNotes = () => {
    this.props
      .getAppointmentNotes(this.state.selectedAppointment.id)
      .then((response) => {
        if (response.length > 0) {
          let array = response;
          for (let index = 0; index < array.length; index++) {
            if (array[index].is_private) {
              this.setState({ privateNotes: array[index].note, privateNotesUpdatedBy: array[index].user.name });
            } else if (!array[index].is_private) {
              this.setState({
                patientNotes: array[index].note,
                patientNotesId: array[index].id,
                patientotesUpdatedBy: array[index].user.name
              });
            }
          }
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  toggle = () => {
    this.props.closeNotesModal();
    this.setState({ open: !this.state.open, error: null });
  };

  sendPatientPublicData = () => {
    const { patientNotes, patientNotesId } = this.state;
    if (isEmpty(patientNotes))
      this.setState({
        isPublishingError: true,
        error: "Cannot publish empty notes.",
      });
    if (!isEmpty(patientNotes) && patientNotesId !== null) {
      this.setState({ isLoading: true });
      this.addPatientPublicAppointmentNotes();
    } else {
      this.setState({ isPublishingError: true });
    }
  };

  addPatientPublicAppointmentNotes() {
    const { selectedAppointment, patientNotesId, patientNotes } = this.state;
    this.props
      .addPublicAppointmentNotes(selectedAppointment.id, patientNotesId)
      .then((response) => {
        console.log({ response });
        this.setState({ isLoading: false, isPublishingSuccess: true });
      })
      .catch((err) => {
        console.log({ err });
        this.setState({ isLoading: false });
        if (isEmpty(patientNotes)) {
          this.setState({
            isPublishingError: true,
            error: "Cannot publish empty notes.",
          });
        } else {
          this.setState({
            isPublishingError: true,
            error: "Couldn't publish the notes. It's a server generated error.",
          });
        }
      });
  }

  render() {
    const {
      error,
      isLoading,
      isPublishingError,
      isPublishingSuccess,
    } = this.state;

    return (
      <div>
        <Modal isOpen={this.state.open} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Appointment Notes</ModalHeader>
          {!!isPublishingError && <Alert color="danger">{error}</Alert>}
          {!!isPublishingSuccess && (
            <Alert color="success">Notes published successfully.</Alert>
          )}
          <ModalBody>
            <Row className="mb-3">
              <Col xs={3} className="d-flex align-items-center">
                <Label className="m-0">Date</Label>
              </Col>
              <Col xs={9}>
                <Label className="m-0">
                  {moment(
                    this.state.selectedAppointment.appointment_localtime
                  ).format(DATE_TIME_FORMAT.DATE)}
                </Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3} className="d-flex align-items-center">
                <Label className="m-0">Time</Label>
              </Col>
              <Col xs={9}>
                <Label className="m-0">
                  {appointmentStartTimeWithTimeZone(
                    this.state.selectedAppointment
                  ) +
                    " - " +
                    appointmentEndTimeWithTimeZone(
                      this.state.selectedAppointment
                    )}
                </Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={3} className="d-flex align-items-center">
                <Label className="m-0">Purpose</Label>
              </Col>
              <Col sm={9}>
                <Label className="m-0">
                  {this.state.selectedAppointment.reason_name}
                </Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={3} className="d-flex align-items-center">
                <Label className="m-0">Updated By</Label>
              </Col>
              <Col sm={9}>
                <Label className="m-0">
                  {this.state.privateNotesUpdatedBy}
                </Label>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="d-flex align-items-center">
                <Label className="m-0">Private Notes</Label>
              </Col>
              <Col sm={9}>
                <textarea
                  disabled={true}
                  className="m-0 "
                  value={this.state.privateNotes}
                ></textarea>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="d-flex align-items-center">
                <Label className="m-0">Updated By</Label>
              </Col>
              <Col sm={9}>
                <Label className="m-0">
                  {this.state.patientotesUpdatedBy}
                </Label>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="d-flex align-items-center">
                <Label className="m-0">Patient Notes</Label>
              </Col>
              <Col sm={9}>
                <textarea
                  disabled={true}
                  className="m-0"
                  value={this.state.patientNotes}
                ></textarea>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button
              className="btn btn-primary"
              onClick={this.sendPatientPublicData}
              disabled={!!isLoading && true}
            >
              {isLoading ? "Publishing..." : "Publish"}
            </Button>

            <Button
              className="btn btn-outline-dark"
              onClick={this.toggle}
              disabled={!!isLoading && true}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getAppointmentNotes, addPublicAppointmentNotes },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentNotes);
