import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Card,
  Form,
  Alert,
  Input,
  Button,
  CardBody,
  CardGroup,
  Container,
  InputGroup,
} from "reactstrap";

import LoginLogo from "../../../src/assets/img/brand/logo.svg";
import { sendForgotPassword } from "../../../src/redux/store/actions/UserActions";

import { validateEmail } from "../../utilities/Validator";

import Loader from "../Common/Loader";

class ForgotPasswordSendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
      emptyError: "",
      isLoading: false,
      successMessage: "",
    };
  }

  validateEmailLocally() {
    var isValid = true;

    if (this.state.email === "") {
      this.setState({ emptyError: "This is required" });
      isValid = false;
    } else {
      let response = validateEmail(this.state.email);
      if (response !== "") {
        this.setState({ error: response });
        isValid = false;
      }
    }

    return isValid;
  }

  sendEmail = (e) => {
    e.preventDefault();
    this.setState({ error: "", emptyError: "", successMessage: "" });
    var isValidated = this.validateEmailLocally();

    if (isValidated) {
      this.setState({
        isLoading: true,
        error: null,
      });
      let params = {
        email: this.state.email,
      };

      this.props
        .sendForgotPassword(params)
        .then(() => {
          this.setState({
            isLoading: false,
            successMessage:
              "We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive an email shortly.If you don't receive an email, please make sure you've entered the correct email address, and check your spam folder.",
          });
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            error: "Email not found.",
          });
        });
    }
  };

  validatePasswordLocally() {
    let isValidated = true;

    return isValidated;
  }

  render() {
    const { email } = this.state;

    if (this.state.successMessage)
      return (
        <div className="app flex-row align-items-center app-login">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 login-card">
                    <CardBody>
                      <Form>
                        <img alt="Clinicall logo" src={LoginLogo} />
                        <Alert color="success">
                          {this.state.successMessage}
                        </Alert>
                        <Row>
                          <Col xs="10">
                            <Button
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );

    return (
      <div className="app flex-row align-items-center app-login">
        {this.state.isLoading && <Loader />}
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4 login-card">
                  <CardBody>
                    <Form>
                      <img alt="Clinicall logo" src={LoginLogo} />
                      {this.state.error && (
                        <Alert color="danger">{this.state.error}</Alert>
                      )}
                      <h5 className="my-3">Enter Email Address</h5>
                      <InputGroup className={"mb-3"}>
                        <div className="d-flex flex-column width-100">
                          <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                              this.setState({
                                email: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </InputGroup>
                      {this.state.emptyError && (
                        <p className="error-message mt-n3 pt-1 pb-3">
                          This is required
                        </p>
                      )}

                      <Row>
                        <Col xs="10">
                          <Button
                            color="primary"
                            className="px-4"
                            onClick={this.sendEmail}
                          >
                            Send Email
                          </Button>
                          <div className="reset-password-divider" />
                          <Button
                            color="secondary"
                            onClick={() => this.props.history.goBack()}
                          >
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendForgotPassword }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordSendEmail);
