import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { version } from "../../../package.json";
// import { AppEnvironment } from "../../redux/services/api/ApiClient";

class AppFooter extends Component {
  render() {
    return (
      <div>
        <span>© 2020 ClinicLive, All rights reserved.</span>
        <span>
          {" "}
          Version : {version}{" "}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppFooter);
