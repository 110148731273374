import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./api/GenericResponseHandler";
import { ApiClient } from "./api/ApiClient";
import StorageConstants from "../../constants/StorageConstants";

export function getUserAppointments(pageNum, sortBy) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_USER_APPOINTMENT}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":pageNum", pageNum);
  if (sortBy === "patient_name") {
    url = url + "&sort=patient_name";
  }
  if (sortBy === "-patient_name") {
    url = url + "&sort=-patient_name";
  }
  if (sortBy === "doctor_name") {
    url = url + "&sort=doctor_name";
  }
  if (sortBy === "-doctor_name") {
    url = url + "&sort=-doctor_name";
  }
  if (sortBy === "reason_name") {
    url = url + "&sort=reason_name";
  }
  if (sortBy === "-reason_name") {
    url = url + "&sort=-reason_name";
  }
  if (sortBy === "appointment_time") {
    url = url + "&sort=appointment_time";
  }
  if (sortBy === "-appointment_time") {
    url = url + "&sort=-appointment_time";
  }
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function getAppointmentNotes(appId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_APPOINTMENT_NOTES}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function cancelUserAppointment(appId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.CANCEL_APPOINTMENT}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url).then(handleResponse).catch(handleError);
}

export function addAppointmentNotes(params, appId, noteId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.ADD_APPOINTMENT_NOTES}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);
  url = url.replace(":noteId", noteId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .put(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function addPublicAppointmentNotes(appId, noteId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.ADD_PUBLIC_APPOINTMENT_NOTES}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);
  url = url.replace(":noteId", noteId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url).then(handleResponse).catch(handleError);
}

export function configureAppointmentNotes(params, appId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.CONFIGURE_APPOINTMENT_NOTES}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .post(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function getUserAppointmentLogs(appId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_USER_APPOINTMENT_LOGS}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appId", appId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function getUserAppointmentHistory(status, pageNum, sortBy) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_USER_APPOINTMENT_HISTORY}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":pageNum", pageNum);
  if (status !== "ALL") {
    url = url + "&status=" + status;
  }
  if (sortBy === "name") {
    url = url + "&sort=name";
  }
  if (sortBy === "-name") {
    url = url + "&sort=-name";
  }
  if (sortBy === "appointment_time") {
    url = url + "&sort=appointment_time";
  }
  if (sortBy === "-appointment_time") {
    url = url + "&sort=-appointment_time";
  }
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function startAppointment(id) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url =
    `${API_CONSTANTS.API_ROUTES.START_APPOINTMENT_1}` +
    id +
    `${API_CONSTANTS.API_ROUTES.START_APPOINTMENT_2}`;
  url = url.replace(":id", clinicId);
  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url).then(handleResponse).catch(handleError);
}

export function getAvailableDates() {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_AVAILABLE_DATES}`;
  url = url.replace(":id", clinicId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function getAvailableAppointments(startDate, endDate) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_AVAILABLE_APPOINTMENTS}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":startDate", startDate);
  url = url.replace(":endDate", endDate);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function getAppointmentReasons() {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.GET_APPOINTMENT_REASONS}`;
  url = url.replace(":id", clinicId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).get(url).then(handleResponse).catch(handleError);
}

export function scheduleAppointment(appointmentId, params) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.SCHEDULE_APPOINTMENT}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appointment_id", appointmentId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .put(url, params)
    .then(handleResponse)
    .catch(handleError);
}

export function endAppointmentCall(appointmentId) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.END_APPOINTMENT_CALL}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appointment_id", appointmentId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token).put(url).then(handleResponse).catch(handleError);
}

export function setAppointmentAssignee(appointmentId, params) {
  var clinicId = localStorage.getItem(StorageConstants.CLINIC_ID);
  var url = `${API_CONSTANTS.API_ROUTES.ASSIGN_APPOINTMENT}`;
  url = url.replace(":id", clinicId);
  url = url.replace(":appointment_id", appointmentId);

  var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
  return ApiClient(token)
    .put(url, params)
    .then(handleResponse)
    .catch(handleError);

}




export default {
  getUserAppointments,
  startAppointment,
  getUserAppointmentLogs,
  getUserAppointmentHistory,
  getAppointmentNotes,
  addAppointmentNotes,
  addPublicAppointmentNotes,
  cancelUserAppointment,
  configureAppointmentNotes,
  setAppointmentAssignee,
  getAppointmentReasons,
  getAvailableDates,
  getAvailableAppointments,
  scheduleAppointment,
  endAppointmentCall,
};
