import axios from 'axios';
import { API_CONSTANTS } from '../../../../src/constants/ApiConstants';

export const ApiClient = (token = null, skipApiSegment) => {
	const apiUrl = AppEnvironment.config().baseURL;

	const defaultOptions = {
		headers: {
			[API_CONSTANTS.HEADER_KEYS.AUTHORIZATION]: token ? `${token || null}` : '',
			'x-api-token': token ? `${token || null}` : '', // TODO: is this key required?
			[API_CONSTANTS.HEADER_KEYS.CONTENT_TYPE]: API_CONSTANTS.CONTENT_TYPES.APPLICATION_JSON
		},
	};

	return {
		get: (url, options = {}) => {
			if (!isCompleteUrl(url)) {
				url = `${apiUrl}${url}`;
			}
			console.log('REQUESTING GET API CALL : \nURL : ', `${url}`, 'OPTIONS : ', options);
			return axios.get(`${url}`, { ...defaultOptions, ...options });
		},
		post: (url, data, options = {}) => {
			if (!isCompleteUrl(url)) {
				url = `${apiUrl}${url}`;
			}
			console.log('REQUESTING POST API CALL : \nURL : ', `${url}`, 'DATA : ', data, 'OPTIONS : ', options);
			return axios.post(`${url}`, data, { ...defaultOptions, ...options });
		},
		put: (url, data, options = {}) => {
			if (!isCompleteUrl(url)) {
				url = `${apiUrl}${url}`;
			}
			console.log('REQUESTING PUT API CALL : \nURL : ', `${url}`, 'DATA : ', data, 'OPTIONS : ', options);
			return axios.put(`${url}`, data, { ...defaultOptions, ...options });
		},
		delete: (url, options = {}) => {
			if (!isCompleteUrl(url)) {
				url = `${apiUrl}${url}`;
			}
			console.log('REQUESTING DELETE API CALL : \nURL : ', `${url}`, 'OPTIONS : ', options);
			return axios.delete(`${url}`, { ...defaultOptions, ...options });
		},
	};
};

export const BUILD_MODE = {
	LOCAL: "local",
	DEV: "dev",
	QA: "qa",
	STAGING: "staging",
	PROD: "prod",
	DEMO: "demo"
};

export const AppEnvironment = {


	/* Configure these two options before build release: */
	/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
	showEnvironmentSelector: false,
	buildMode: process.env.REACT_APP_BUILD_MODE ? BUILD_MODE[process.env.REACT_APP_BUILD_MODE] : BUILD_MODE.PROD,

	allConfigurations: {
		local: {
			baseURL: "http://10.165.12.253:56000/frldp"
		},
		dev: {
			baseURL: "https://ancient-crag-42996.herokuapp.com",
			server: "DEV"
		},
		qa: {
			baseURL: "https://demo9.folio3.com:3001",
			server: "QA"
		},
		staging: {
			baseURL: "https://staging.clinicliveapp.com:2053",
			server: "STAG"
		},
		prod: {
			baseURL: "https://clinicliveapp.com:2053",
			server: ""
		},
		demo: {
			baseURL: "http://10.165.6.241:8080/api"
		}
	},

	config(buildMode) {
		const mode = buildMode || this.buildMode;
		return this.allConfigurations[mode];
	}
};

function isCompleteUrl(url = "") {
	return url.indexOf('https://') > -1 || url.indexOf('http://') > -1;
}
