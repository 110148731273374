import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../components/Login';
import { ROUTE_CONSTANTS } from '../constants/Routes';
import Appointments from '../components/Appointments/Appointments';
import AppointmentsHistory from '../components/Appointments/AppointmentHistory/AppointmentHistory';
import Clients from '../components/Clients/Clients';
import Users from '../components/Users/Users';
import UserNew from '../components/Users/UserNew';
import UserEdit from '../components/Users/UserEdit';
import AppointmentRoom from '../components/Appointments/AppointmentRoom';
import Invitation from '../components/Invitation/invitations';
import Settings from '../components/Settings/Settings';
import ResetPassword from '../components/Profile/ResetPassword';
import PrivateRoute from 'react-router-private';
import StorageConstants from '../constants/StorageConstants';
import ForgotPasswordSendEmail from '../components/ForgotPassword/ForgotPasswordSendEmail';
import SetNewPassword from '../components/ForgotPassword/SetNewPassword';


// import Login from "../components/Login";
// import Users from "../components/Users/Users";
// import UserNew from "../components/Users/UserNew";
// import UserEdit from "../components/Users/UserEdit";
// import Clients from "../components/Clients/Clients";
// import Settings from "../components/Settings/Settings";
// import StorageConstants from "../constants/StorageConstants";
// import Invitation from "../components/Invitation/invitations";
// import ResetPassword from "../components/Profile/ResetPassword";
// import Appointments from "../components/Appointments/Appointments";
// import AppointmentRoom from "../components/Appointments/AppointmentRoom";
// import AppointmentsHistory from "../components/Appointments/AppointmentHistory/AppointmentHistory";

class AppRouter extends Component {
  render() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    return (
      <Router>
        <div>
          <Switch>
            <PrivateRoute
              path={ROUTE_CONSTANTS.APPOINTMENTS}
              component={Appointments}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.CLIENTS}
              component={Clients}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.USERS}
              component={Users}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.INVITATION}
              component={Invitation}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.ADD_USERS}
              component={UserNew}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.EDIT_USERS}
              component={UserEdit}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.APPOINTMENT_START}
              component={AppointmentRoom}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.SETTINGS}
              component={Settings}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.RESET_PASSWORD}
              component={ResetPassword}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />
            <PrivateRoute
              path={ROUTE_CONSTANTS.APPOINTMENTS_HISTORY}
              component={AppointmentsHistory}
              authStatus={token != null}
              nonLoggedInComponent={Login}
            />

            <Route exact path={ROUTE_CONSTANTS.FORGOT_PASSWORD_SEND_EMAIL} component={ForgotPasswordSendEmail} />
            <Route exact path={ROUTE_CONSTANTS.SET_NEW_PASSWORD} component={SetNewPassword} />
            <Route exact path={ROUTE_CONSTANTS.LOGIN} component={Login} />
            <Route exact path={ROUTE_CONSTANTS.LANDING_SCREEN} component={Login} />

          </Switch>
        </div>
      </Router >
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default connect(mapStateToProps)(AppRouter);
