import React from 'react';
import { Provider } from "react-redux"
import PropTypes from 'prop-types'
import './App.scss';
import AppRouter from './routers/AppRouter';

const App = ({ routes, store }) => {
	return (
		<Provider store={store}>
			<AppRouter />
		</Provider>
	);
}

App.propTypes = {
	/** List of routes  */
	routes: PropTypes.arrayOf(PropTypes.shape({
		// =========== ToDo: FILL THIS ==========
	})).isRequired,
	/** ReDux Store object */
	store: PropTypes.object.isRequired,
};

export default App;
