import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { AppHeader, AppFooter } from "@coreui/react";
import imageCompression from "browser-image-compression";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Label,
  Alert,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";

import {
  getClinicInfo,
  saveClinicInfo,
} from "../../redux/store/actions/ClinicActions";
import { logoutUser } from "../../redux/store/actions/UserActions";
import {
  getClinicSpecialities,
  deleteClinicSpecialities,
} from "../../redux/store/actions/ClinicActions";
import { getAppointmentReasons } from "../../redux/store/actions/AppointmentActions";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { STATE_LIST } from "../../constants/GeneralConstants";
import StorageConstants from "../../constants/StorageConstants";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Common/Loader";
import ModalComponent from "../Common/Modal";

import ClinicPurpose from "./ClinicPurpose";
import ClinicProgress from "./ClinicProgress";
import ClinicSpecialities from "./ClinicSpecialities";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      city: "",
      state: "",
      street: "",
      zipCode: "",
      attachment: "",
      call_quota: "",
      quota_used: "",
      purposeList: [],
      contactEmail: "",
      contactNumber: "",
      specialityList: [],
      serverPurposeList: [],
      serverSpecialityList: [],
      quota_usage_updated_date: "",

      error: null,
      saveData: false,
      isLoading: false,
      displayModal: false,
      bookingEnabled: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem(StorageConstants.AUTH_TOKEN);
    token == null && this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
    this.getInitialData();
  }

  getInitialData() {

    this.fetchClinic();
    this.getClinicSpecialities();
    this.getClinicPupose();
  }

  fetchClinic() {
    this.setState({ isLoading: true });
    this.props
      .getClinicInfo()
      .then(() => {
        this.setState({
          isLoading: false,
          name: this.props.clinic.name,
          city: this.props.clinic.city,
          state: this.props.clinic.state,
          street: this.props.clinic.street,
          zipCode: this.props.clinic.zipcode,
          contactEmail: this.props.clinic.email,
          contactNumber: this.props.clinic.phone,
          call_quota: this.props.clinic.call_quota,
          quota_used: this.props.clinic.quota_used,
          attachment: this.props.clinic.attachment_url,
          bookingEnabled: this.props.clinic.booking_enabled,
          quota_usage_updated_date: this.props.clinic.quota_usage_updated_date,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  getClinicSpecialities() {
    this.props
      .getClinicSpecialities()
      .then((response) => {
        this.setState({ serverSpecialityList: response });
      })
      .catch((error) => { });
  }

  getClinicPupose() {
    this.props
      .getAppointmentReasons()
      .then((response) => {
        this.setState({ serverPurposeList: response });
      })
      .catch((error) => { });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckBoxChange = (event) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        practiceManager: !prevState.practiceManager,
      };
    });
  };

  handleBookingChange = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        bookingEnabled: !prevState.bookingEnabled,
      };
    });
  };

  updatePurposeList = (list) => {
    this.setState({ purposeList: list });
  };

  updateSpecialityList = (list) => {
    this.setState({ specialityList: list });
  };

  getSpeciality() {
    console.log("Server List", this.state.serverSpecialityList);
    console.log("purposeList", this.state.specialityList);

    var finalList = this.state.serverSpecialityList;

    this.state.specialityList.forEach((spec) => {
      if (spec.id) {
        let item = this.state.serverSpecialityList.filter(
          (p) => p.id == spec.id
        );
        var index = finalList.indexOf(item[0]);
        if (index > -1) {
          finalList.splice(index, 1);
        }
      } else {
        finalList.push(spec);
      }
    });

    return finalList;
  }

  getPurpose() {
    console.log("Server List p", this.state.serverPurposeList);
    console.log("purposeList p ", this.state.purposeList);

    var finalList = this.state.serverPurposeList;
    this.state.purposeList.forEach((purpose) => {
      if (purpose.id) {
        let item = this.state.serverPurposeList.filter(
          (p) => p.id === purpose.id
        );
        var index = finalList.indexOf(item[0]);
        if (index > -1) {
          finalList.splice(index, 1);
        }
      } else {
        finalList.push(purpose);
      }
    });

    return finalList;
  }

  handleSubmit = (event) => {
    event.stopPropagation();
    this.setState({ error: null });
    let params = {
      clinic: {
        name: this.state.name,
        city: this.state.city,
        state: this.state.state,
        street: this.state.street,
        zipcode: this.state.zipCode,
        email: this.state.contactEmail,
        reasons: this.state.purposeList,
        phone: this.state.contactNumber,
        attachment: this.state.attachment,
        reasons: this.getPurpose(),
        specialities: this.getSpeciality(),
        booking_enabled: this.state.bookingEnabled,
        practice_manager: this.state.practiceManager,
      },
    };
    this.setState({ isLoading: true });
    this.props
      .saveClinicInfo(params)
      .then(() => {

        this.setState({ isLoading: false });
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log("Error Clinic", error);
        this.setState({ isLoading: false, error: error.message });
      });
  };

  saveImage = (file) => {
    imageCompression(file, { maxSizeMB: 1 })
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onload = () => {
          this.setState({
            attachment: reader.result,
          });
        };
      })
      .catch(() => { });
  };
  goToResetPasswordScreen = () => {
    this.props.history.push(ROUTE_CONSTANTS.RESET_PASSWORD);
  };

  logOut = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.props.history.replace(ROUTE_CONSTANTS.LOGIN);
      })
      .catch(() => { });
  };

  render() {
    const { error, isLoading, displayModal, redirectToDashboard } = this.state;
    return (
      <React.Fragment>
        <AppHeader fixed>
          <Suspense>
            <Header
              showRightMenu={true}
              logOut={this.logOut}
              goToResetPasswordScreen={this.goToResetPasswordScreen}
            />
          </Suspense>
        </AppHeader>
        {isLoading && <Loader />}
        <Card className="border-0 border-radius-0">
          <CardHeader>
            <h2>Practice Information</h2>
          </CardHeader>

          <CardBody>
            <Row>
              <Col sm={7}>
                <Form
                  className="max-width-500"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {error && <Alert color="danger">{error}</Alert>}
                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-firstName">
                        Practice Name
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder="Practice Name"
                        maxLength="50"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-lastName">
                        Street Address
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="street"
                        name="street"
                        value={this.state.street}
                        onChange={this.handleChange}
                        placeholder="Street Address"
                        maxLength="50"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-email">
                        City
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="Email"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange}
                        placeholder="City"
                        maxLength="50"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-profession">
                        State
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="hf-profession"
                        name="state"
                        type="select"
                        value={this.state.state}
                        onChange={this.handleChange}
                        placeholder="State"
                        maxLength="50"
                      >
                        {STATE_LIST.map((value, index) => (
                          <option key={`opt-${index}`} value={value}>
                            {value}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-profession">
                        Zip Code
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="hf-profession"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange}
                        placeholder="Zip Code"
                        maxLength="6"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-profession">
                        Contact Number
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="hf-profession"
                        name="contactNumber"
                        value={this.state.contactNumber}
                        onChange={this.handleChange}
                        placeholder="Contact Number"
                        maxLength="15"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex"></Col>
                    <Col xs="12" md="8">
                      <FormGroup check row>
                        <Input
                          type="checkbox"
                          id="bookingEnabled"
                          name="bookingEnabled"
                          className="form-check-input"
                          value={this.state.bookingEnabled}
                          checked={this.state.bookingEnabled}
                          onChange={this.handleBookingChange}
                        />
                        <Label
                          check
                          for="bookingEnabled"
                          htmlFor="bookingEnabled"
                          className="form-check-label my-auto"
                        >
                          {" "}
                          Enable Clients to Schedule Appointments
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex">
                      <Label className="my-auto" htmlFor="hf-profession">
                        Contact Email
                      </Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        id="hf-profession"
                        name="contactEmail"
                        disabled={true}
                        value={this.state.contactEmail}
                        onChange={this.handleChange}
                        placeholder="Contact Email"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex align-items-start">
                      <Label htmlFor="hf-profession">Image</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <figure className="profile-photo">
                        <img src={this.state.attachment} alt="user-icon" />
                      </figure>
                      <Input
                        accept="image/*"
                        type="file"
                        onChange={(event) => {
                          this.saveImage(event.target.files[0]);
                        }}
                        onClick={(event) => (event.target.value = null)}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex align-items-start">
                      <Label htmlFor="hf-profession">Specialities</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <ClinicSpecialities
                        updateSpecialityList={this.updateSpecialityList}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="3" className="d-flex align-items-start">
                      <Label htmlFor="hf-profession">Appointment Purpose</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <ClinicPurpose
                        updatePurposeList={this.updatePurposeList}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="form-actions">
                    <Button
                      className="btn btn-outline-dark mr-2"
                      color="secondary"
                      size="sm"
                      onClick={() => this.setState({ displayModal: true })}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-primary"
                      size="sm"
                      color="success"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
              <Col
                sm={5}
                className="d-flex justify-content-center align-items-center mb-5 mt-3 mb-sm-0 mt-sm-0"
              >
                <ClinicProgress
                  call_quota={this.state.call_quota}
                  quota_used={this.state.quota_used}
                  quota_usage_updated_date={this.state.quota_usage_updated_date}
                />
              </Col>
            </Row>
          </CardBody>
          <ModalComponent
            isOpen={displayModal}
            actionHandler={() => this.props.history.goBack()}
            cancelHandler={() => {
              this.setState({ displayModal: false });
            }}
            title="Are you sure you want to cancel without saving?"
          />
        </Card>

        {redirectToDashboard && <Redirect to={{ pathname: "/dashboard" }} />}

        <AppFooter className="settings-footer" fixed>
          <Suspense>
            <Footer />
          </Suspense>
        </AppFooter>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinic: state.clinic.clinic,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClinicInfo,
      saveClinicInfo,
      logoutUser,
      getClinicSpecialities,
      deleteClinicSpecialities,
      getAppointmentReasons,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
