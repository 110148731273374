import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Input, Alert } from "reactstrap";
import "react-circular-progressbar/dist/styles.css";

import "../../styles/styles.css";
import { getAppointmentReasons } from "../../redux/store/actions/AppointmentActions";

class ClinicPurpose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      newItem: "",
      purposeList: [],
      dataToSend: null,
      alreadyAdded: false,
    };
  }
  componentDidMount() {
    this.setState({ data: this.props.data });
    this.getClinicPupose();
  }

  getClinicPupose() {
    this.props
      .getAppointmentReasons()
      .then((response) => {
        this.setState({ purposeList: response });
        this.updatePurpose();
      })
      .catch((error) => {});
  }

  deleteItem(index) {
    let item = this.state.purposeList.filter((p) => p.id != index);
    this.setState({ purposeList: item });

    var data = [];
    item.forEach((s) => {
      let val = {};
      if (s.id == 0) {
        val = { name: s.name };
      } else {
        val = { id: s.id };
      }
      data.push(val);
    });

    this.props.updatePurposeList(data);
    this.setState({ newItem: "" });
  }

  newItem(event) {
    let value = event.target.value.trimStart();
    this.setState({ newItem: value });
  }

  onClickHandler() {
    let value = this.state.newItem.trimStart();
    let itemFound = this.state.purposeList.find((item) => item.name === value);
    if (itemFound) {
      this.setState({ newItem: "", alreadyAdded: true });
    }
    if (value !== "" && !itemFound) {
      let item = { id: 0, name: this.state.newItem };
      var newArr = this.state.purposeList;
      newArr.push(item);
      this.setState({ purposeList: newArr });
      this.setState({ newItem: "", alreadyAdded: false });
      this.updatePurpose();
    }
  }

  updatePurpose() {
    var data = [];
    this.state.purposeList.forEach((s) => {
      let item = {};
      if (s.id === 0) {
        item = { name: s.name };
      } else {
        item = { id: s.id };
      }
      data.push(item);
    });
    this.props.updatePurposeList(data);
  }

  render() {
    let displayList = this.state.purposeList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const { alreadyAdded } = this.state;

    return (
      <div>
        {displayList.map((spec, index) => (
          <div className="node" key={index}>
            <Button>{spec.name}</Button>
            <Button
              className="close-node"
              onClick={() => this.deleteItem(spec.id)}
            >
              <i className="ficon-times1"></i>
            </Button>
          </div>
        ))}

        <div className="d-flex flex-row mb-2">
          <Input
            maxLength={40}
            placeholder="Enter Purpose"
            value={this.state.newItem}
            onChange={(e) => this.newItem(e)}
          />
          <Button className="ml-2" onClick={() => this.onClickHandler()}>
            Add
          </Button>
        </div>
        {!!alreadyAdded && <Alert color="danger">Added purpose is already in the list.</Alert>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getAppointmentReasons }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicPurpose);
