// import { convertLocalTimeToDate } from "../utilities/TimeZone";

export const PLATFORMS = {
    MOBILE: 'mobile',
    WEB: 'web',
};

export const DASHBOARD_ITEMS = {
    APPOINTMENT: 0,
    CLIENTS: 1,
    USERS: 2
};

export const USER_COMPONENTS = {
    USERS_LISTING: 0,
    USER_NEW: 1,
    USERS_EDIT: 2
};

export const SELECTED_APPOINTMENT = {
    UPCOMING: 0,
    PAST: 1,
    NEW_APPOINTMENT: 2
};

export const APPOINTMENT_STATUSES_LIST = [
    { key: "0", value: "ALL" },
    { key: "1", value: "COMPLETED" },
    { key: "2", value: "CANCELLED" },
    { key: "3", value: "MISSED" }
];

export const APPOINTMENT_STATUSES = {
    ALL: "ALL",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    MISSED: "MISSED"
}

export const APPOINTMENT_PURPOSE_LIST = [
    { key: "0", value: "Routine Check Up" },
    { key: "1", value: "Sickness" },
    { key: "2", value: "Follow Up" },
    { key: "3", value: "Other" }

];

export const DATE_TIME_FORMAT = {
    DATE: 'MM/DD/YYYY'
}

export const SEND_NOTES_INTERVAL = 30000;

export const TEAM_ROLE = {
    PRACTICE_MANAGER: "practice_manager",
    STAFF: "staff"
};

export const STATE_LIST = [
	'Alabama',
	'Alaska',
	'American Samoa',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'District of Columbia',
	'Federated States of Micronesia',
	'Florida',
	'Georgia',
	'Guam',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Marshall Islands',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Northern Mariana Islands',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Palau',
	'Pennsylvania',
	'Puerto Rico',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virgin Island',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
];
