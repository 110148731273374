import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Video from "twilio-video";
import { Button, Card } from "reactstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import { createLocalVideoTrack } from 'twilio-video';

export default class VideoComponent extends Component {
  constructor(props) {
    const { data } = props;
    super();
    this.state = {
      identity: data.identity,
      token: data.token,
      roomNameErr: false, // Track error for room name TextField
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      activeRoom: "", // Track the current active room
      mutedVideo: false,
      mutedAudio: false,
      buttonText: "Joining Room",
      joining: false,
      hasLeftRoom: false,
      redirectToAppointments: false
    };


  }

  joinRoom = async () => {

    if (!this.state.identity.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }

    this.setState({ buttonText: "Joining Room...", joining: true });
    let connectOptions = {
      name: this.state.identity,
    };

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
    }

    // Join the Room with the token from the server and the
    // LocalParticipant's Tracks.
    Video.connect(this.state.token, connectOptions)
      .then(this.roomJoined, (error) => {
        alert("Could not connect to Twilio: " + error.message);

      })
      .catch((error) => {
        alert("Could not connect to Twilio: " + error.message);
      });
  };

  attachTracks(tracks, container) {
    tracks.forEach((track) => {
      container.appendChild(track.track.attach());
    });
  }

  // Attaches a track to a specified DOM container
  attachParticipantTracks(participant, container) {
    var tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
  }

  detachTracks = (tracks) => {
    tracks.forEach((track) => {
      if (track.track) {
        track.track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      }
    });
  };

  detachParticipantTracks = (participant) => {
    var tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  };

  roomJoined = (room) => {
    // Called when a participant joins a room
    console.log("Joined as '" + this.state.identity + "'");

    this.setState({
      activeRoom: room,
      localMediaAvailable: true,
      hasJoinedRoom: true,
    });

    // Attach LocalParticipant's Tracks, if not already attached.
    var previewContainer = this.refs.localMedia;
    if (!previewContainer.querySelector("video")) {
      this.attachParticipantTracks(room.localParticipant, previewContainer);
    }

    // Attach the Tracks of the Room's Participants.
    room.participants.forEach(participant => {
      participant.tracks.forEach(publication => {
        if (publication.track) {
          var previewContainer = this.refs.remoteMedia;
          if (publication.track.mediaStreamTrack) {
            previewContainer.appendChild(publication.track.attach());
          }
        }
      });
    
     participant.on('trackSubscribed', track => {
      var previewContainer = this.refs.remoteMedia;
      if (track.mediaStreamTrack) {
        previewContainer.appendChild(track.attach());
      }
      });
    });

    // When a Participant joins the Room, log the event.
    room.on('participantConnected', participant => {
      console.log(`Participant "${participant.identity}" connected`);
    
      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          const track = publication.track; 
          var previewContainer = this.refs.remoteMedia;
          if (track.mediaStreamTrack) {
            previewContainer.appendChild(track.attach());
          }
        }
      });
    
      participant.on('trackSubscribed', track => {
        var previewContainer = this.refs.remoteMedia;
        if (track.mediaStreamTrack) {
          previewContainer.appendChild(track.attach());
        }
      });
    });

    // When a Participant adds a Track, attach it to the DOM.
    room.on("trackAdded", (track, participant) => {
      console.log(participant.identity + " added track: " + track.kind);
      var previewContainer = this.refs.remoteMedia;
      this.attachTracks([track], previewContainer);
    });

    // When a Participant removes a Track, detach it from the DOM.
    room.on("trackRemoved", (track, participant) => {
      console.log(participant.identity + " removed track: " + track.kind);
      this.detachTracks([track]);
    });

    room.on('participantDisconnected', this.onParticipantDisconnected);
    room.on('trackUnsubscribed', this.onParticipantUnpublishedTrack);

    room.on("trackDisabled", (track, participant) => {
      console.log("Participant '" + participant.identity + " 'trackDisabled")
      console.log("Participant '" + track + " 'trackDisabled")
      this.detachTracks([track]);
    });

    room.on("trackEnabled", (track, participant) => {
      console.log("Participant '" + participant.identity + " 'trackEnabled")
      console.log("Participant '" + track + " 'trackEnabled")
      var previewContainer = this.refs.remoteMedia;
      this.attachTracks([track], previewContainer);
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on("disconnected", () => {
      if (this.state.previewTracks) {
        this.state.previewTracks.forEach((track) => {
          track.stop();
        });
      }
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.setState({
        activeRoom: null,
        hasJoinedRoom: false,
        localMediaAvailable: false,
      });
      // window.close();
      this.setState({ hasLeftRoom: true, redirectToAppointments: true });
      // this.setState({ redirectToAppointments: true });
    });
  };
   
  onParticipantUnpublishedTrack(track) {
    if (track.mediaStreamTrack) {
      const htmlElements = track.detach();
        for (let htmlElement of htmlElements) {
          htmlElement.remove();
        }
    }
  }

  onParticipantDisconnected(participant) {
    // When a (remote) participant disconnects, detach the assiciated tracks
    for (let track of participant.tracks) {
      if (track.mediaStreamTrack) {
        const htmlElements = track.detach();
        for (let htmlElement of htmlElements) {
          htmlElement.remove();
        }
      }
    }
  }

  toggleVideo = () => {
    var activeRoom = this.state.activeRoom;

    activeRoom.localParticipant.videoTracks.forEach(publication => {
      if (publication.track.isEnabled) {
        publication.track.disable();
        return;
      }
      publication.track.enable();
    });
  
    this.setState({ mutedVideo: !this.state.mutedVideo });
  };

  toggleAudio = () => {
    var activeRoom = this.state.activeRoom;
    activeRoom.localParticipant.audioTracks.forEach(publication => {
      if (publication.isTrackEnabled) {
        publication.track.disable();
        return;
      }
      publication.track.enable();
    });
    this.setState({ mutedAudio: !this.state.mutedAudio });
  };

  componentDidMount() {
    this.joinRoom();
  }

  leaveRoom = (isAppointmentEnd) => {
    this.state.activeRoom.disconnect();
    this.setState({
      hasJoinedRoom: false,
      localMediaAvailable: false,
      buttonText: "Join Room",
      joining: false,
    });
    this.props.endVideoCall(isAppointmentEnd);
  };

  endTheCallHandler = () => {
    confirmAlert({
      title: "",
      message: "Would you like to end this appointment?",
      buttons: [
        {
          label: `Yes I'm done`,
          onClick: () => this.leaveRoom(true),
        },
        {
          label: `No I'll be right back`,
          onClick: () => this.leaveRoom(false),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  render() {
    // Only show video track after user has joined a room
    let showLocalTrack = this.state.localMediaAvailable ? (
      <div className="flex-item">
        <div ref="localMedia" />
      </div>
    ) : (
        ""
      );
    // Hide 'Join Room' button if user has already joined a room.
    let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (
      <Button className="critical" onClick={this.endTheCallHandler}>
        <i className="ficon-phone1"></i>
      </Button>
    ) : !this.state.hasLeftRoom ? (
      <Button
        className="not-rounded"
        color="primary"
        disabled={this.state.joining}
        onClick={this.joinRoom}
      >
        {this.state.buttonText}
      </Button>
    ) : null;

    let videoIcon = this.state.mutedVideo ? (
      <i className="ficon-video-slash11"></i>
    ) : (
        <i className="ficon-video11"></i>
      );

    let audioIcon = this.state.mutedAudio ? (
      <i className="ficon-microphone-slash11"></i>
    ) : (
        <i className="ficon-microphone11"></i>
      );

    return (
      <Card className="video-window">
        <div className="video-wrapper">
          <div className="host-video">{showLocalTrack}</div>
          <div className="video-btns">
            {this.state.hasJoinedRoom && (
              <Button onClick={this.toggleAudio}>{audioIcon}</Button>
            )}
            {joinOrLeaveRoomButton}
            {this.state.hasJoinedRoom && (
              <Button onClick={this.toggleVideo}>{videoIcon}</Button>
            )}
          </div>
          <div className="guest-video" ref="remoteMedia" />
        </div>
        {this.state.redirectToAppointments ? (
          <Redirect to={{ pathname: "/appointments" }} />
        ) : (
            ""
          )}
      </Card>
    );
  }
}
