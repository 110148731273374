import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import logo from "../../../src/assets/img/brand/logo.svg";
import sygnet from "../../../src/assets/img/brand/sygnet.png";

import StorageConstants from "../../constants/StorageConstants";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: new Array(2).fill(false),
    };
  }

  toggle = (i) => {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      dropdownOpen: newArray,
    });
  };

  render() {
    var loggedInUser = localStorage.getItem(StorageConstants.LOGGED_IN_USER);
    var role = localStorage.getItem(StorageConstants.LOGGED_IN_USER_ROLE);

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, alt: "CoreUI Logo" }}
          minimized={{ src: sygnet, alt: "CoreUI Logo" }}
        />
        <span className="business-title">{this.props.clinic.name}</span>

        <Dropdown
          defaultValue="sdfgsd"
          className="profile-btn"
          isOpen={this.state.dropdownOpen[1]}
          toggle={() => {
            this.toggle(1);
          }}
        >
          <DropdownToggle caret>
            <span className="d-none d-lg-inline d-xl-inline">
              {loggedInUser}
            </span>
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem
              header
              className="d-lg-none"
              display="md"
              mobile="true"
            >
              {loggedInUser}
            </DropdownItem>
            {!this.props.showRightMenu && role !== "3" && (
              <DropdownItem onClick={this.props.goToSettings}>
                <i className="ficon-cog11"></i>Settings
              </DropdownItem>
            )}

            {!this.props.hideResetOption && (
              <DropdownItem onClick={this.props.goToResetPasswordScreen}>
                <i className="ficon-key"></i>Reset Password
              </DropdownItem>
            )}

            <DropdownItem onClick={this.props.logOut}>
              <i className="ficon-sign-out11"></i>Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinic: state.clinic.clinic,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
