import { LOADING_ACTIONS } from '../../../constants/ActionConstants';

export function request(type, payload, loadingDispatch = false) {

    if (loadingDispatch) {
        loadingDispatch({ type: LOADING_ACTIONS.START_LOADING });
    }

    return { type, payload };
}

export function success(type, payload, loadingDispatch = false) {
    if (loadingDispatch) {
        loadingDispatch({ type: LOADING_ACTIONS.STOP_LOADING })
    }
    return { type, payload };
}

export function failure(type, payload, loadingDispatch = false) {
    if (loadingDispatch) {
        loadingDispatch({ type: LOADING_ACTIONS.STOP_LOADING })
    }
    return { type, payload };
}