import {
  USER_ACTIONS,
  GET_USER_ACTIONS,
} from "../../../constants/ActionConstants";

const initialState = {
  user: null,
  allUsers: {},
};

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case GET_USER_ACTIONS.GET_USER_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case USER_ACTIONS.LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default userProfile;
