export default {
  items: [
    {
      name: "Appointments",
      url: "/appointments",
      icon: "ficon-calendar-alt1",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Clients",
      url: "/clients",
      icon: "ficon-user-friends1",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Invitation",
      url: "/invitation",
      icon: "ficon-envelope-open-text1",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Appointment History",
      url: "/appointments-history",
      icon: "ficon-history1",
      badge: {
        variant: "info",
      },
    },
    {
      name: "Logout",
      url: "/login",
      icon: "ficon-sign-out11",
      badge: {
        variant: "info",
      },
    },
    {
      divider: true,
    },
  ],
};
