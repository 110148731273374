import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Alert,
  CardBody,
  CardHeader,
  Pagination,
} from "reactstrap";

import { ROUTE_CONSTANTS } from "../../constants/Routes";
import { getClients } from "../../redux/store/actions/ClientsAction";

import Loader from "../Common/Loader";
import PaginateBar from "../Common/Pagination";

import AppointmentSchedule from "../Appointments/AppointmentSchedule";

const sortingDefaultState = {
  asecName: false,
  descName: false,
  asecEmail: false,
  descEmail: false,
};
class ClientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      success: false,
      isLoading: false,
      sortedBy: {
        asecName: false,
        descName: false,
        asecEmail: false,
        descEmail: false,
      },

      data: {},
      clients: [],
      userId: null,
      sortingBy: "",
      totalCount: 1,
      currentPage: 1,
    };
  }

  componentDidMount() {
    const { currentPage } = this.state;
    this.fetchClients(currentPage);
  }

  isDataProvided = () => {
    const { data } = this.state;
    return data && data.length > 0;
  };

  toggle = () => {
    this.setState({ open: !this.state.open, error: null });
  };

  submissionHandler = () => {
    this.setState({ success: true });
  };

  fetchClients = (num, sortBy = "") => {
    this.setState({ isLoading: true });
    this.props
      .getClients(num, sortBy)
      .then((response) => {
        this.setState({
          isLoading: false,
          clients: response.list,
          totalCount: response.total_count,
        });
      })
      .catch((err) => {
        if (err.code === 401) {
          this.props.history.push(ROUTE_CONSTANTS.LANDING_SCREEN);
        }
        this.setState({ isLoading: false });
      });
  };

  sortArrayByName = () => {
    let pageNum = 1;
    let criteria = "name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecName: true,
      },
    });
    this.fetchClients(pageNum, criteria);
  };

  sortArrayByDescName = () => {
    let pageNum = 1;
    let criteria = "-name";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descName: true,
      },
    });
    this.fetchClients(pageNum, criteria);
  };

  sortArrayByEmail = () => {
    let pageNum = 1;
    let criteria = "email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        asecEmail: true,
      },
    });
    this.fetchClients(pageNum, criteria);
  };

  sortArrayByDescEmail = () => {
    let pageNum = 1;
    let criteria = "-email";
    this.setState({
      sortingBy: criteria,
      currentPage: pageNum,
      sortedBy: {
        ...sortingDefaultState,
        descEmail: true,
      },
    });
    this.fetchClients(pageNum, criteria);
  };

  getPageData = (num) => {
    const { sortingBy } = this.state;
    this.setState({ currentPage: num });
    this.fetchClients(num, sortingBy);
  };

  render() {
    const {
      open,
      userId,
      clients,
      success,
      sortedBy,
      isLoading,
      totalCount,
      currentPage,
    } = this.state;
    const { asecEmail, descEmail, asecName, descName } = sortedBy;
    return (
      <div className="animated fadeIn">
        {!!isLoading && <Loader />}
        <Card className="mb-0 border-0 border-radius-0 mb-5">
          <CardHeader>
            <Row>
              <Col sm={7} className="d-flex align-items-center">
                <i className="fa fa-align-justify"></i>
                <h2>Clients</h2>
              </Col>
              <Col sm={5} className="mt-2 mt-sm-0">
                <button
                  className="btn btn-outline-dark float-right"
                  onClick={this.props.toggleRendering}
                >
                  Add New Client
                </button>
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            {!!success && (
              <Alert color="success">
                Your appointment has been successfully scheduled.
              </Alert>
            )}
            {!!this.props.success && (
              <Alert color="success">Client Successfully Created.</Alert>
            )}
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Name</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByName}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecName && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescName}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descName && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="m-0">
                    <div className="flex-centered">
                      <span>Email</span>
                      <span className="d-flex flex-column">
                        <i
                          onClick={this.sortArrayByEmail}
                          className={`fa fa-caret-up fa-lg pointer ${
                            asecEmail && "active-sorting"
                          }`}
                        ></i>
                        <i
                          onClick={this.sortArrayByDescEmail}
                          className={`fa fa-caret-down fa-lg pointer ${
                            descEmail && "active-sorting"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="m-0">
                    State
                  </th>
                  <th scope="col" className="m-0">
                    City
                  </th>
                  <th scope="col">Zipcode</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Appointments</th>
                  <th scope="col">Active</th>
                  <th scope="col">Appointment</th>
                </tr>
              </thead>
              <tbody>
                {clients.length > 0 &&
                  clients.map((user) => (
                    <tr key={user.id.toString()}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.state}</td>
                      <td>{user.city}</td>
                      <td>{user.zipcode}</td>
                      <td>{user.phone}</td>
                      <td>{user.appointments_count}</td>
                      <td>{user.is_approved ? "Yes" : "No"}</td>
                      <td>
                        <button
                          className="has-icon"
                          onClick={() => {
                            this.toggle();
                            this.setState({ userId: user.id });
                          }}
                        >
                          <i className="ficon-calendar-alt11"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                {clients.length === 0 && (
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Record Found</td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {totalCount / 15 > 1 && (
              <Pagination className="pagination-wrapper">
                <PaginateBar
                  pageNum={currentPage}
                  changePageNo={this.getPageData}
                  totalPages={Math.ceil(totalCount / 15)}
                />
              </Pagination>
            )}
          </CardBody>
        </Card>
        {!!open && (
          <AppointmentSchedule
            open={open}
            userId={userId}
            toggle={this.toggle}
            getPageData={this.getPageData}
            submissionHandler={this.submissionHandler}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getClients }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientsList));
