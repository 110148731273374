export const USER_ACTIONS = {
    // user login
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
}

export const APPOINTMENT_ACTIONS = {
    APPOINTMENT_SUCCESS: 'APPOINTMENT_SUCCESS',
    APPOINTMENT_HISTORY_SUCCESS: 'APPOINTMENT_HISTORY_SUCCESS'
}

export const CLIENTS_ACTIONS = {
    CLIENTS_REQUEST: 'CLIENTS_REQUEST',
    CLIENTS_SUCCESS: 'CLIENTS_SUCCESS',
    CLIENTS_FAILURE: 'CLIENTS_FAILURE',
}


export const GET_USER_ACTIONS = {
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',
}

export const USER_ASSIGNEE = {
    USER_ASSIGNEE_REQUEST: 'USER_ASSIGNEE_REQUEST',
    USER_ASSIGNEE_SUCCESS: 'USER_ASSIGNEE_SUCCESS',
    USER_ASSIGNEE_FAILURE: 'USER_ASSIGNEE_FAILURE',
}

export const USER_DELETE = {
    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
}

export const USER_APPROVE = {
    USER_APPROVE_REQUEST: 'USER_APPROVE_REQUEST',
    USER_APPROVE_SUCCESS: 'USER_APPROVE_SUCCESS',
    USER_APPROVE_FAILURE: 'USER_APPROVE_FAILURE',
}

export const USER_INVITATION = {
    USER_INVITATION_REQUEST: 'USER_APPROVE_REQUEST',
    USER_INVITATION_SUCCESS: 'USER_APPROVE_SUCCESS',
    USER_INVITATION_FAILURE: 'USER_APPROVE_FAILURE',
}

export const GET_CLINIC = {
    GET_CLINIC_REQUEST: 'GET_CLINIC__REQUEST',
    GET_CLINIC_SUCCESS: 'GET_CLINIC__SUCCESS',
    GET_CLINIC__FAILURE: 'GET_CLINIC__FAILURE',
}

export const LOADING_ACTIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING'
}