import { USER_INVITATION } from "../../../constants/ActionConstants";

const initialState = {
  invitations: [],
};

const invitations = (state = initialState, action) => {
  switch (action.type) {
    case USER_INVITATION.USER_INVITATION_SUCCESS:
      return {
        ...state,
        invitations: action.payload,
      };

    default:
      return state;
  }
};

export default invitations;
