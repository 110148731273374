import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Row,
  Col,
  Label,
  Modal,
  Alert,
  Button,
  Spinner,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import "../../styles/styles.css";

import {
  convertLocalTimeToDate,
  appointmentEndTimeWithTimeZone,
  appointmentStartTimeWithTimeZone,
} from "../../utilities/TimeZone";
import {
  getAvailableDates,
  scheduleAppointment,
  getAppointmentReasons,
  getAvailableAppointments,
} from "../../../src/redux/store/actions/AppointmentActions";

import Loader from "../Common/Loader";

class AppointmentSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      selectedSlot: null,
      isSubmitting: false,
      selectedReason: null,
      isSlotsLoading: false,
      isCalenderOpen: false,
      availableDateList: [],
      isSubmissionError: false,
      selectedDate: new Date(),
      appointmentReasonsList: [],
      availableAppointmentList: [],
    };
  }

  componentDidMount() {
    this.fetchAvailableDates();
    this.fetchAppointmentReasons();
    this.fetchAvailableAppointments();
  }

  fetchAvailableAppointments = () => {
    let sDate = new Date(this.state.selectedDate).toISOString();

    var endTime = this.state.selectedDate;
    endTime.setHours(23, 59, 59, 999);
    var end = endTime.toISOString();

    this.setState({ isSlotsLoading: true });
    this.props
      .getAvailableAppointments(sDate, end)
      .then((res) => {
        this.setState({ availableAppointmentList: res });
        this.setState({ isSlotsLoading: false });
      })
      .catch(() => {
        this.setState({ isSlotsLoading: false });
      });
  };

  fetchAvailableDates = () => {
    this.setState({ isLoading: true });
    this.props
      .getAvailableDates()
      .then((res) => {
        this.setState({ availableDateList: res });
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  fetchAppointmentReasons = () => {
    this.setState({ isLoading: true });
    this.props
      .getAppointmentReasons()
      .then((res) => {
        this.setState({ appointmentReasonsList: res });
        this.setState({ selectedReason: this.state.appointmentReasonsList[0] });
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onChange = (date) => {
    this.setState({ selectedSlot: null, selectedDate: date }, () => {
      this.fetchAvailableAppointments();
    });
    this.showHideCalender();
  };

  showHideCalender = () => {
    this.setState({ isCalenderOpen: !this.state.isCalenderOpen });
  };

  reasonHandler = (event) => {
    this.setState({ selectedReason: event.target.value });
  };

  handleSumbit = () => {
    let selectedPurpose = this.state.appointmentReasonsList.find(
      (item) =>
        item.name ===
        (typeof this.state.selectedReason === "object"
          ? this.state.selectedReason.name
          : this.state.selectedReason)
    );

    this.setState({ isSubmitting: true });
    this.props
      .scheduleAppointment(this.state.selectedSlot, {
        reason_id: selectedPurpose.id,
        patient_id: this.props.userId,
      })
      .then((res) => {
        if (res.success) {
          this.props.toggle();
          this.props.getPageData(1);
          this.props.submissionHandler();
        }
        this.setState({ isSubmitting: false });
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
        this.setState({ isSubmissionError: true });
      });
  };

  slotsHandler = (data) => {
    this.setState({ selectedSlot: data.id });
  };

  render() {
    const {
      availableAppointmentList,
      appointmentReasonsList,
      isSubmissionError,
      isSlotsLoading,
      isCalenderOpen,
      selectedDate,
      isSubmitting,
      isLoading,
    } = this.state;

    const { open, toggle } = this.props;

    // const monthAvailability = Object.keys(this.state.availableDateList);
    if (isLoading) return <Loader />;
    return (
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>New Appointment</ModalHeader>
        <ModalBody>
          {isSubmissionError && (
            <Alert color="danger">The information is incorrect</Alert>
          )}
          <Row className="mb-3">
            <Col sm={3} className="d-flex align-items-center">
              <Label className="m-0">Date</Label>
            </Col>
            <Col sm={9} className="has-calendar">
              <Button className="btn btn-outline-dark print-date pr-5">
                {convertLocalTimeToDate(selectedDate)}
              </Button>

              {isCalenderOpen && (
                <Calendar
                  className="react-calendar mb-1 mt-1"
                  onChange={this.onChange}
                  value={selectedDate}
                  // tileDisabled={({ date }) => {
                  //   let available;
                  //   for (let i = 0; i < monthAvailability.length; i++) {
                  //     available =
                  //       moment(date).format("YYYY-MM") ===
                  //       moment(monthAvailability[i]).format("YYYY-MM");
                  //   }
                  //   return available;
                  // }}
                />
              )}

              {!isCalenderOpen && (
                <Button
                  className="btn btn-outline-dark date-btn"
                  onClick={this.showHideCalender}
                >
                  <i className="fa fa-calendar"></i>
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={3} className="d-flex align-items-center">
              <Label className="m-0">Purpose</Label>
            </Col>
            <Col sm={9}>
              <select
                className="custom-select form-control"
                id="hf-profession"
                onChange={this.reasonHandler}
              >
                {appointmentReasonsList
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ name }, index) => (
                    <option value={name} key={`list-${index}`}>
                      {name}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={12} className="time-slot">
              {!isSlotsLoading ? (
                availableAppointmentList.length !== 0 ? (
                  availableAppointmentList.map((appointment, index) => (
                    <Button
                      className={`btn ${
                        appointment.id === this.state.selectedSlot
                          ? "btn-dark"
                          : "btn-outline-dark"
                      } py-2`}
                      key={`slot-${index}`}
                      onClick={() => this.slotsHandler(appointment)}
                    >
                      {appointmentStartTimeWithTimeZone(appointment) +
                        " - " +
                        appointmentEndTimeWithTimeZone(appointment)}
                    </Button>
                  ))
                ) : (
                  <Col sm={12} className="time-slot text-center">
                    {`There is no available appointment for the selected date.
                      Please choose a different date.`}
                  </Col>
                )
              ) : (
                <Col sm={12} className="time-slot text-center">
                  <Spinner />
                </Col>
              )}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-outline-dark"
            disabled={!!isSubmitting && true}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={this.handleSumbit}
            disabled={!!isSubmitting && true}
          >
            {!isSubmitting ? "Save" : "Saving..."}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAvailableDates,
      scheduleAppointment,
      getAppointmentReasons,
      getAvailableAppointments,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentSchedule);
