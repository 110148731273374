import { GET_CLINIC } from "../../../constants/ActionConstants";

const initialState = {
  clinic: {},
};

const clinic = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLINIC.GET_CLINIC_SUCCESS:
      return {
        ...state,
        clinic: action.payload,
      };

    default:
      return state;
  }
};

export default clinic;
