import { identity, pickBy, reduce as _reduce, trim } from "lodash";

const activePageToPageIndex = (activePage) => {
  return activePage - 1; // index always starts from 0 and goes to n-1.
};

const removeEmptyKeys = (args) => {
  return pickBy(args, identity);
};

const parameterStringify = (urlParams) => {
  // remove any empty key from the object. Any such key which doesn't have any value.
  urlParams = pickBy(urlParams, identity);
  // Browsers standard.
  const parameterSeparator = "&";
  const urlParameters = _reduce(urlParams, (result, val, key) => {
    result += `${key}=${val}${parameterSeparator}`;
    return result;
  }, "");
  // there should be no `&` in start or end of parameter string.
  // So trim it.
  return trim(urlParameters, parameterSeparator);
};




export {
  activePageToPageIndex,
  parameterStringify,
  removeEmptyKeys
}
